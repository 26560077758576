import { defineComponent as _defineComponent } from 'vue'
import { vModelText as _vModelText, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "p-inputnumber p-component p-inputwrapper p-inputwrapper-filled" }
const _hoisted_2 = ["id", "disabled"]

import { computed, ref, watch } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'CustomInputNumber',
  props: {
  maxFractionDigits: {
    type: Number,
    default: 2,
    required: false
  },
  modelValue: {
    type: Number,
    default: 0
  },
  disabled: {
    type: Boolean,
    default: false
  },
  id: {
    type: String,
    default: 'inputId'
  }
},
  emits: ['update:modelValue'],
  setup(__props, { emit: __emit }) {

const emit = __emit

const props = __props

const filled = computed(() => {
  return localModel.value.toString() !== ''
})

const localModel = ref<string>(props.modelValue.toString());
const inputError = ref(false)

watch(localModel, (value) => {
  console.log("Fdjskfjdsk")
  const standardizedInput = value.replace(',', '.');
  const numberPattern = /^-?\d+(\.\d+)?$/;
  if (numberPattern.test(standardizedInput)) {
    const valueNumber = parseFloat(standardizedInput)
    inputError.value = false
    console.log("ayes")
    emit('update:modelValue', valueNumber)
  } else {
    console.log("no")
    inputError.value = true
  }
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("span", _hoisted_1, [
    _withDirectives(_createElementVNode("input", {
      id: props.id,
      class: _normalizeClass(['p-inputtext','p-component',{ 
        'p-filled': filled.value,
        'p-invalid': inputError.value
      }]),
      type: "text",
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((localModel).value = $event)),
      disabled: props.disabled
    }, null, 10, _hoisted_2), [
      [_vModelText, localModel.value]
    ])
  ]))
}
}

})