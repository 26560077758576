<script setup lang="ts">
import { ref, toRef } from 'vue';
import { useField } from 'vee-validate';
import DataBrowserSelect from '@/components/DataBrowser/DataBrowseSelect.vue';

const props = defineProps({
  name: {
    type: String,
    required: true,
  },
  label: {
    type: String,
    required: true,
  },
  type: {
    type: String,
    default: 'text',
  },
  disabled: {
    type: Boolean,
    default: false
  },
  fieldClass: {
    type: String,
    required: false,
    default: ''
  },
  setParentVolume: {
    type: Function,
    required: false,
    default: () => { return { } }
  },  
  selectedVolume: {
    type: String,
    required: false
  },
  leaf: {
    type: String,
    required: false,
    default: ''
  },
  minimize: {
    type: Boolean,
    required: false,
    default: false
  }
});

const { errorMessage, value: fieldValue } = useField(toRef(props, 'name'));

let _path = fieldValue?.value?.path ?? null
if (!_path && fieldValue?.value?.volumeMountAlias) {
  _path = ''
}

const path = ref(_path)

// Function
const onSelectFile = (values: any) => {
  fieldValue.value = values

  if (values && values.volumeMountAlias && values.path === '') {
    path.value = './'
  } else {
    path.value = values.path
  }  
  if (values.volumeMountAlias) {
    props.setParentVolume(values.volumeMountAlias)
  }
}

const fillData = (dem: any) => {
  for (const key of Object.keys(dem)) {
    switch(key){
    case 'volumeMountAlias': 
    
      break
    case 'totalRecords':
    case 'offset':
    case 'limit':
      if (typeof dem[key] === 'string') {
        dem[key] = Number(dem[key])
      }
      break
    }
  }
  path.value = dem.path
  fieldValue.value = dem  
}

const browserRef = ref()
const setBrowserVolume = (volumeMountAlias: string) => {
  browserRef.value.setBrowserVolume(volumeMountAlias)
}

const showDataBrowserSelect = (event:any) => {
  browserRef.value?.showBrowseSelect()
  event.preventDefault()
}

defineExpose({
  name: props.name,
  fillData,
  setBrowserVolume,
})
</script>

<template>
  <div class="field" :class="fieldClass">
    <div class="p-inputgroup flex-1">
      <span :class="{ 'p-float-label': true, 'p-float-label-margin': minimize } ">
        <InputText v-model="path" :aria-describedby="`${name}-help`" :disabled="true" @contextmenu="showDataBrowserSelect" @dblclick="showDataBrowserSelect"
          :class="{ 'p-invalid': errorMessage }" :type="type" :readonly="true" :id="name" />        
        <DataBrowserSelect 
          @select-file="onSelectFile" 
          :selected="fieldValue ?? {}" 
          :title="label" 
          ref="browserRef" 
          :leaf="leaf"></DataBrowserSelect>
        <label :for="name" v-if="fieldValue && !minimize"><small>{{ label }}</small></label>

      </span>
    </div>
    <small :id="`${name}-help`" class="p-error">{{ errorMessage }}</small>
  </div>
</template>

<style lang="scss" scoped>
.p-float-label-margin {
  margin-top: 0rem;  
}
</style>