<template>
  <multiple-input-list title="Definition of cameras" :toggleableRow="true" :toggleableRowTitleCb="toggleableRowTitleCb" :save-preset-enable="savePresetEnable && !readonlyFlag"
    :defaultRowValue="defaultRowValue" v-model:modelValue="data[PRESET_TYPES.CAMERA_DEF]" :minRowsCount="1"
    :item-label="'camera'"
    :rowClass="'float-label'" :show-title="showTitle" :disableRowRemove="readonlyFlag">
    <template #form="{ value }">
      <div class="p-fluid formgrid grid">
        <div class="field col-12 md:col-6">
          <span class="p-float-label">
            <InputText id="name" v-model="value.name" :disabled="readonlyFlag" />
            <label class="mlwn-label-ellipsis" for="name">Name</label>
          </span>
          <small class="mlwn-label-ellipsis block">User-defined camera name, images can be later mapped to a camera rule based on this name.</small><br>
        </div>

        <!--focalLenght_mm-->
        <div class="field col-12 md:col-6">
          <span class="p-float-label">
            <span class="mlwn-col-input">
              <CustomInputNumber 
                :maxFractionDigits="20" 
                id="focalLenght_mm" 
                v-model="value.focalLenght_mm" 
                :disabled="readonlyFlag" />
              <label class="mlwn-label-ellipsis" for="focalLenght_mm">Focal length <strong>[mm]</strong></label>
            </span>
            <small class="mlwn-label-ellipsis block">&nbsp;</small><br>
          </span>
        </div>

        <!--image_width_px-->
        <div class="field col-12 md:col-2">
          <span class="p-float-label">
            <span class="mlwn-col-input">
              <CustomInputNumber 
                :maxFractionDigits="10" 
                id="image_width_px" 
                v-model="value.image_width_px" 
                :use-grouping="false" 
                :disabled="readonlyFlag" />
              <CustomInputNumber
                :maxFractionDigits="10" 
                id="image_height_px" 
                v-model="value.image_height_px" 
                :use-grouping="false" 
                :disabled="readonlyFlag" />

              <label class="mlwn-label-ellipsis" for="image_width_px">Image Size Width, Height <strong>[px]</strong></label>
            </span>
            <br>          
          </span>
        </div>  

        <!--px_width_um-->
        <div class="field col-12 md:col-2">
          <span class="p-float-label">
            <span class="mlwn-col-input">
              <CustomInputNumber
                :maxFractionDigits="20" 
                id="px_width_um" 
                v-model="value.px_width_um" 
                :use-grouping="false" 
                :disabled="readonlyFlag" />
              <CustomInputNumber
                :maxFractionDigits="20" 
                id="px_height_um" 
                v-model="value.px_height_um" 
                :use-grouping="false" 
                :disabled="readonlyFlag" />
              <label class="mlwn-label-ellipsis" for="px_width_um">Pixel Size Width, Height <strong>[&#181;m]</strong></label>
            </span>
            <br>                        
          </span>
        </div>

        <!--lensShift_x_mm-->
        <div class="field col-12 md:col-2">
          <span class="p-float-label">
            <span class="mlwn-col-input">
              <CustomInputNumber
                :maxFractionDigits="20" 
                id="lensShift_x_mm" 
                v-model="value.lensShift_x_mm" 
                :use-grouping="false" 
                :disabled="readonlyFlag" />
              <CustomInputNumber
                :maxFractionDigits="20" 
                id="lensShift_y_mm" 
                v-model="value.lensShift_y_mm" 
                :use-grouping="false" 
                :disabled="readonlyFlag" />
              <label class="mlwn-label-ellipsis" for="lensShift_x_mm">Principal Point Offset X, Y <strong>[mm]</strong></label>
            </span>
            <br>
          </span> 
        </div>


        <!--up_x_m-->
        <div class="field col-12 md:col-3">
          <span class="p-float-label">
            <span class="mlwn-col-input">
              <InputNumber :maxFractionDigits="20" id="up_x_m" v-model="value.up_x_m" :disabled="readonlyFlag" :use-grouping="false"/>
              <InputNumber :maxFractionDigits="20" id="up_y_m" v-model="value.up_y_m" :disabled="readonlyFlag" :use-grouping="false"/>
              <InputNumber :maxFractionDigits="20" id="up_z_m" v-model="value.up_z_m" :disabled="readonlyFlag" :use-grouping="false"/>
              <label class="mlwn-label-ellipsis" for="up_x_m">Initial Up Vector <strong>X</strong>, <strong>Y</strong>, <strong>Z</strong></label>
            </span>
            <small>Must be a unit vector</small><br>
          </span>
        </div>

        <!--look_x_m-->
        <div class="field col-12 md:col-3">
          <span class="p-float-label">
            <span class="mlwn-col-input">
              <InputNumber :maxFractionDigits="20" id="look_x_m" v-model="value.look_x_m" :disabled="readonlyFlag" :use-grouping="false"/>
              <InputNumber :maxFractionDigits="20" id="look_y_m" v-model="value.look_y_m" :disabled="readonlyFlag" :use-grouping="false"/>
              <InputNumber :maxFractionDigits="20" id="look_z_m" v-model="value.look_z_m" :disabled="readonlyFlag" :use-grouping="false"/>
              <label class="mlwn-label-ellipsis" for="look_x_m">Initial Look Vector <strong>X</strong>, <strong>Y</strong>, <strong>Z</strong></label>
            </span>
            <small class="mlwn-label-ellipsis block">Must be a unit vector, perpendicular to Up Vector</small>
          </span>
        </div>
        
        <div class="field col-12" v-if="readonlyFlag && false">
          <div>Camera attributes: <span v-for="layer of value.options" :key="layer.key">{{ layer.key }}=<strong>{{ layer.value }}</strong>; </span></div>
        </div>

        <div class="field col-12" v-if="!readonlyFlag || true">
          <multiple-input-list-simple title="Camera attributes" :defaultRowValue="defaultOptionRowValue"
            v-model:modelValue="value.options" :rowClass="'float-label'" :toggleableRow="true" item-label="camera attribute"
            :toggleableRowTitleCb="toggleableOptionRowTitleCb" :disable-row-remove="readonlyFlag">
            <template #form="{ value }">
              <div class="p-fluid formgrid grid">
                <div class="field col-12 md:col-6">
                  <span :class="{ 'p-float-label': !readonlyFlag }">
                    <InputText v-model="value.key" :disabled="readonlyFlag"/>
                    <label v-if="!readonlyFlag" for="key">Key</label>
                  </span>
                </div>
                <div class="field col-12 md:col-6">
                  <span :class="{ 'p-float-label': !readonlyFlag }">
                    <InputText v-model="value.value" :disabled="readonlyFlag" />
                    <label v-if="!readonlyFlag" for="value">Value</label>
                  </span>
                </div>
              </div>
            </template>
          </multiple-input-list-simple>
        </div>
      </div>
    </template>
  </multiple-input-list>
</template>

<script lang="ts" setup>
import { reactive, ref, computed, watch, Ref } from 'vue'
import MultipleInputList from '@/components/UI/Form/MultipleInputList.vue'
import MultipleInputListSimple from '@/components/UI/Form/MultipleInputListSimple.vue'
import { PRESET_TYPES } from '@/helpers/constants';
import { CameraDefModelType, CameraOptionDefinition, CameraDefinitions } from '@/helpers/types/PresetTypes'
import CustomInputNumber from '../components/CustomInputNumber.vue';

const props = defineProps({
  modelValue: {
    type: Object,
    default: () => { }
  },
  showTitle: {
    type: Boolean,
    required: false,
    default: true
  },
  savePresetEnable: {
    type: Boolean,
    required: false,
    default: false
  }
})

const changeEmitName = 'update:modelValue'
const emits = defineEmits<{ (e: typeof changeEmitName, id: string): void }>()

const model = computed({
  get: () => props?.modelValue,
  set: (value: any) => {
    emits(changeEmitName, value)
  },
})

const toggleableRowTitleCb = (value: CameraDefModelType) => {
  return `Camera: ${value.name}`
}

const toggleableOptionRowTitleCb = (value: CameraOptionDefinition) => {
  return `Option: ${value.key}`
}

const state = reactive(model.value ?? {
  name: '',
  px_width_um: 0,
  px_height_um: 0,
  focalLenght_mm: 0,
  image_width_px: 0,
  image_height_px: 0,
  up_x_m: 0,
  up_y_m: 1,
  up_z_m: 0,
  look_x_m: 0,
  look_y_m: 0,
  look_z_m: -1,
  lensShift_x_mm: 0,
  lensShift_y_mm: 0,
  options: []
})

const defaultRowValue = () => {
  return {
    name: '',
    px_width_um: 0,
    px_height_um: 0,
    focalLenght_mm: 0,
    image_width_px: 0,
    image_height_px: 0,
    up_x_m: 0,
    up_y_m: 1,
    up_z_m: 0,
    look_x_m: 0,
    look_y_m: 0,
    look_z_m: -1,
    lensShift_x_mm: 0,
    lensShift_y_mm: 0,
    options: []
  }
}

const defaultOptionRowValue = () => {
  return {
    key: '',
    value: ''
  }
}

const readonlyFlag = ref(false)
const setReadonly = (readonly:boolean) => {
  readonlyFlag.value = readonly
}

defineExpose({
  setReadonly,
})

watch(state, (newValue) => {
  model.value = newValue
}, { deep: true })

const data: Ref<any> = ref(model.value ?? {
  [PRESET_TYPES.CAMERA_DEF]: []
})
watch(data, (newValue) => {
  model.value = newValue as CameraDefinitions
}, { deep: true })
</script>

<style lang="scss" scoped>
.formgrid {
  width: 100% !important;
  margin-left: 0;
}
</style>