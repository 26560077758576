import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "grid col-12 formgrid"
}
const _hoisted_2 = { class: "field col-12 md:col-6" }
const _hoisted_3 = { class: "p-input-icon-left" }
const _hoisted_4 = { class: "field col-12 md:col-6" }
const _hoisted_5 = { class: "p-input-icon-left" }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = {
  key: 0,
  class: "pl-0",
  style: {"list-style-type":"none"}
}
const _hoisted_8 = { key: 0 }
const _hoisted_9 = { key: 1 }
const _hoisted_10 = { key: 2 }
const _hoisted_11 = ["onClick"]
const _hoisted_12 = { class: "text-right" }
const _hoisted_13 = { class: "p-input-icon-left" }
const _hoisted_14 = { class: "flex flex-wrap gap-2 justify-content-end" }
const _hoisted_15 = {
  key: 0,
  class: "pi pi-circle-fill",
  style: {"color":"var(--primary-color)"}
}
const _hoisted_16 = {
  key: 0,
  class: "pi pi-folder-open"
}
const _hoisted_17 = {
  key: 0,
  class: "pi pi-file"
}
const _hoisted_18 = {
  key: 1,
  class: "pi pi-folder"
}
const _hoisted_19 = ["innerHTML"]
const _hoisted_20 = ["onClick"]
const _hoisted_21 = ["innerHTML"]

import { ref, watch } from 'vue'
import { useConfig } from '@/composables/useConfig'
import { useDataBrowser } from '@/composables/useDataBrowser'
import { DataBrowserEntity, DataBrowserEntityType, DataBrowserSortOption, DataBrowserSortColumn, Volume } from '@/gql/graphql'
import { DropdownChangeEvent } from 'primevue/dropdown';
import type { Ref } from 'vue'
import { MenuItem } from 'primevue/menuitem';
import { TreeTablePageEvent, TreeTableSortEvent } from 'primevue/treetable';
import debounce from 'lodash.debounce'
import prettyBytes from 'pretty-bytes';
import { useVolumes } from '@/composables/useVolumes';

type DataBrowserTreeNode = {
  key: number
  leaf: boolean
  data: {
    name: string,
    size: string,
    type: string | undefined,
    path: string,
    relativePath: string,
    isCurrent: boolean,
  }
}

/**
 * Object selected will contain the following: {volumeMountAlias: 'string', path: 'string'}, according to this value in props, path should be pre-selected in DBS
 */

export default /*@__PURE__*/_defineComponent({
  __name: 'DataBrowseSelect',
  props: {
  selected: {
    type: Object,
    required: false
  },
  title: {
    type: String,
    required: false,
    default: ''
  },
  buttonText: {
    type: String,
    required: false
  },
  preselectedVolumeMountAliases: {
    type: Array<{ mountAlias: String, name: String }>,
    required: false
  },
  leaf: {
    type: String,
    required: false,
    default: ''
  },
},
  emits: ['selectFile'],
  setup(__props, { expose: __expose, emit: __emit }) {


// imports

const { getVolumes } = useVolumes()
const getVolumesObject = getVolumes()
const { volumes, refetch: refetchVolumes } = getVolumesObject

// custom classes

const props = __props

const { getDataBrowserStatus, getDataBrowserList } = useDataBrowser()
const { getConfig } = useConfig()

const grouppedVolumes: Ref<Storage[]> = ref([])

const nodes = ref()
const visible = ref(false)
const volumeMountAliasDropdown: Ref<string | undefined> = ref(undefined)
const storageIdDropdown: Ref<string | undefined> = ref(undefined)
const volumeMountAlias: Ref<string> = ref('')
const storageVolumes: Ref<Volume[]> = ref([])
const dataBrowserReady = ref(false)
const dataBrowserErrors: Ref<string[]> = ref([])
const loading = ref(false)
const limit = ref(50)
const offset = ref(0)
const totalRecords = ref(0)
const dirPath = ref('')
const search = ref('')
const query = ref({
  limit: limit.value,
  offset: offset.value,
  dirPath: '',
  search: '',
  volumeMountAlias: '',
  sort: {
    column: DataBrowserSortColumn.Name,
    order: DataBrowserSortOption.Asc
  }
})
const home: Ref<MenuItem> = ref({
  icon: 'pi pi-home',
  // label: 'root',
  command: () => {
    dataBrowserListReload('')
  },
});
const items: Ref<MenuItem[]> = ref([]);

const storageMask = ref('')
const volumeMask = ref('')

const emit = __emit

function selectFile(node: DataBrowserTreeNode) {
  emit('selectFile', {
    volumeMountAlias: volumeMountAlias.value,
    name: node.data.name,
    dirPath: dirPath.value,
    path: node.data.relativePath,
    limit: limit.value,
    offset: offset.value,
    totalRecords: totalRecords.value,
  })
  visible.value = false
}

// query filter builder

const selectedVolume = ref('')
const setBrowserVolume = (_volumeMountAlias: string) => {
  volumeMountAlias.value = _volumeMountAlias
  selectedVolume.value = _volumeMountAlias
}

const showBrowseSelect = () => {
  visible.value = true
}

__expose({
  setBrowserVolume,
  showBrowseSelect,
})

const clearVolumeMountAlias = () => {
  volumeMountAliasDropdown.value = undefined
  storageMask.value = ''
  volumeMask.value = ''
}

const isLeaf = (node: DataBrowserTreeNode) => {
  if (props.leaf === '') {
    return true
  }
  else if (props.leaf === 'dir') {
    return !node.leaf
  }
  else if (props.leaf === 'file') {
    return node.leaf
  }
  else {
    return false
  }
}

const formatName = (node: DataBrowserTreeNode, search: string) => {
  const text = node.data.name
  let html = text
  if (search !== "") {
    try {
      new RegExp(search, "ig")
    } catch {
      search = search.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
    }

    const matches = text.matchAll(new RegExp(search, "ig"));
    html = ""
    let stringCursor = 0

    for (const match of matches) {
      if (match.index === undefined) continue
      // text from last position to this match
      html += text.slice(stringCursor, match.index)
      // match in bold
      html += `<strong>${match[0]}</strong>`
      // set cursor to end of this match
      stringCursor = match.index + match[0].length
    }
    // add rest of text
    html += text.slice(stringCursor)
  }
  return html + " "
}
const isSelected = (node: DataBrowserTreeNode) => {
  return props.selected?.path === node.data.relativePath && props.selected?.volumeMountAlias === volumeMountAlias.value
}

// composable utils

const {
  status: dataBrowserStatus,
  error: dataBrowserStatusError,
  load: dataBrowserStatusLoad,
  refetch: dataBrowserStatusRefetch
} = getDataBrowserStatus(volumeMountAlias)

const {
  list: dataBrowserList,
  error: dataBrowserListError,
  load: dataBrowserListLoad,
  refetch: dataBrowserListRefetch
} = getDataBrowserList(query)

// config

const config = getConfig();

// watches 

watch(visible, () => {
  // when user opens modal 
  if (visible.value) {
    refetchVolumes()
    if (props.selected && props.selected.volumeMountAlias?.length > 0) {
      // and file/dir is already selected - set selected volume and browse to its parent directory
      dirPath.value = props.selected.dirPath

      limit.value = props.selected.limit
      offset.value = props.selected.offset
      totalRecords.value = props.selected.totalRecords
      setProperQueryLimitOffset()

      let storage = grouppedVolumes.value.find(s => s.volumes.find((v: Volume) => v.mountAlias === props.selected?.volumeMountAlias))
      storageIdDropdown.value = storage?.mountAlias
      if (storage) {
        storage.volumes.sort((a: any, b: any) => { return a.name - b.name });
        storageVolumes.value = storage.volumes
      }
      volumeMountAliasDropdown.value = props.selected.volumeMountAlias
    }
    else if (selectedVolume.value != '') {
      let storage = grouppedVolumes.value.find(s => s.volumes.find((v: Volume) => v.mountAlias === selectedVolume.value))
      storageIdDropdown.value = storage?.mountAlias
      if (storage) {
        storage.volumes.sort((a: any, b: any) => { return a.name - b.name });
        storageVolumes.value = storage.volumes
      }
      dirPath.value = ""
      volumeMountAliasDropdown.value = selectedVolume.value
    }
    else {
      // and no file/dir is selected - set browse to root
      dirPath.value = ""
    }
    if (volumeMountAliasDropdown.value) dataBrowserStatusReload()
  }
})

watch(volumes, (newValue) => {
  // Create a map to store unique storages with their volumes
  let storageMap = new Map();

  newValue
    .filter((volume) => {
      if (props.preselectedVolumeMountAliases) {
        return props.preselectedVolumeMountAliases?.map(e => e.mountAlias).includes(volume.mountAlias)
      } else { return true }
    })
    .forEach(volume => {
      // Check if storageMap already contains the storage id
      let storage = storageMap.get(volume?.storage?.id);

      // If it doesn't contain, create a new storage object
      if (!storage) {
        storage = {
          id: volume?.storage?.id,
          name: volume?.storage?.name,
          volumes: []
        };
        storageMap.set(volume?.storage?.id, storage);
      }

      // Push the current volume to the storage's volumes array
      storage.volumes.push({
        mountAlias: volume.mountAlias,
        name: volume.name,
        volumeStatusAlias: volume.volumeStatus?.alias,
      });
    });

  // Convert map values to an array and return
  grouppedVolumes.value = Array.from(storageMap.values());
})

const changeStorageId = (event: DropdownChangeEvent) => {
  if (!event.value) {
    if (storageVolumes.value && storageVolumes.value.length > 0) {
      storageIdDropdown.value = storageVolumes.value[0]?.storage?.id
      return
    }
  }
  let _volume = volumes.value.filter(item => item?.storage?.id === event.value)
  _volume.sort((a, b) => { return a.name - b.name });
  storageVolumes.value = _volume
}

const changeVolumeMountAlias = (event: DropdownChangeEvent) => {
  let _volume = volumes.value.find(item => item.mountAlias === event.value)

  volumeMountAlias.value = event.value
  volumeMountAlias.value = _volume?.mountAlias || event.value
  query.value.volumeMountAlias = event.value
  dirPath.value = ""
  query.value.dirPath = ""
  dataBrowserStatusReload()
  dataBrowserListReload()
}

watch(volumeMountAliasDropdown, (newValue) => {
  if (newValue) {
    volumeMountAlias.value = newValue
    let _volume = volumes.value.find(item => item.mountAlias === newValue)
    volumeMountAlias.value = _volume?.mountAlias || newValue.toString()
    query.value.volumeMountAlias = newValue
  }
})

watch(dirPath, (newValue) => {
  query.value.dirPath = dirPath.value
  items.value = newValue?.replace(/^\/(.+)$/, '$1').split('/').reduce((acc, current) => {
    acc.relativePath.push(current)
    const relativePath = acc.relativePath.join("/")
    acc.items.push({
      label: current,
      relativePath,
      command: () => {
        dataBrowserListReload(relativePath)
      }
    })
    return acc
  }, {
    relativePath: [] as String[],
    items: [] as MenuItem[]
  }).items
})

watch(dataBrowserStatus, (newValue) => {
  dataBrowserReady.value = newValue.dataBrowserServiceCreated &&
    newValue.dataBrowserServiceReady &&
    newValue.taskVolumeCreated &&
    newValue.taskVolumeReady
  if (dataBrowserReady.value) {
    dataBrowserListReload()
  }
})

watch(dataBrowserStatusError, (newValue) => {
  dataBrowserErrors.value = []
  if (newValue !== null) {
    newValue.graphQLErrors.forEach(error => {
      dataBrowserErrors.value = [...dataBrowserErrors.value, error.message]
    });
  }
})

watch(dataBrowserList, (newValue) => {
  if (newValue.data) {
    nodes.value = mapDataBrowserList(newValue.data)
    totalRecords.value = newValue.pageInfo.total + 1
  }
})

watch(search, debounce(() => {
  query.value.search = search.value
  dataBrowserListReload();
}, 250))

// utils

const mapDataBrowserList = (list: DataBrowserEntity[]) => {
  if (query.value.offset === 0) list.unshift({
    name: "",
    path: "",
    type: DataBrowserEntityType.Directory
  })
  return list.map((entity, i) => {
    let relativePath = dirPath.value
    if (entity.name.length) {
      relativePath = relativePath.length > 0 ? [dirPath.value, entity.name].join("/") : entity.name
    }
    return {
      key: query.value.offset + i + (query.value.offset === 0 ? 0 : 1),
      data: {
        name: entity.name.length ? entity.name : "<i>(current directory)</i>",
        isCurrent: entity.name.length ? false : true,
        size: entity.type === DataBrowserEntityType.File ? prettyBytes(entity.attributes!.size) : "",
        type: entity.attributes?.extension,
        relativePath,
      },
      leaf: entity.type === DataBrowserEntityType.File
    } as DataBrowserTreeNode
  })
}


const dataBrowserStatusReload = () => {
  dataBrowserStatusLoad() || dataBrowserStatusRefetch()
}

const dataBrowserListReload = (dirPathToChange: string = dirPath.value) => {
  dirPath.value = dirPathToChange
  query.value.dirPath = dirPathToChange

  dataBrowserListLoad() || dataBrowserListRefetch()
}

// events
const sortBrowser = (event: TreeTableSortEvent) => {
  query.value.sort.column = event.sortField as DataBrowserSortColumn
  query.value.sort.order = !event.sortOrder ? DataBrowserSortOption.Asc : (
    event.sortOrder > 0 ? DataBrowserSortOption.Asc : DataBrowserSortOption.Desc
  )
  dataBrowserListReload()
}
const setProperQueryLimitOffset = () => {
  query.value.limit = limit.value
  query.value.offset = offset.value === 0 ? offset.value : offset.value - 1
}

const onExpand = (node: DataBrowserTreeNode) => {
  dirPath.value = node.data.relativePath
  dataBrowserListReload()
}
const onPage = (event: TreeTablePageEvent) => {
  limit.value = event.rows
  offset.value = event.first
  setProperQueryLimitOffset()
  dataBrowserListReload()
}

return (_ctx: any,_cache: any) => {
  const _component_Button = _resolveComponent("Button")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Listbox = _resolveComponent("Listbox")!
  const _component_Breadcrumb = _resolveComponent("Breadcrumb")!
  const _component_Column = _resolveComponent("Column")!
  const _component_TreeTable = _resolveComponent("TreeTable")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Button, {
      "data-cy": "dataBrowseSelect__browseFiles",
      icon: "fa-solid fa-folder-open",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (visible.value = true)),
      "t-tooltip.bottom": "Browse files",
      label: props.buttonText || ''
    }, null, 8, ["label"]),
    _createVNode(_component_Dialog, {
      visible: visible.value,
      "onUpdate:visible": _cache[7] || (_cache[7] = ($event: any) => ((visible).value = $event)),
      modal: "",
      header: __props.title && __props.title.length > 0 ? __props.title : 'Select file',
      style: { width: '800px' }
    }, {
      default: _withCtx(() => [
        (!volumeMountAliasDropdown.value)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _createElementVNode("div", _hoisted_2, [
                _cache[9] || (_cache[9] = _createElementVNode("h6", null, "Select storage", -1)),
                _createElementVNode("span", _hoisted_3, [
                  _cache[8] || (_cache[8] = _createElementVNode("i", { class: "pi pi-search" }, null, -1)),
                  _createVNode(_component_InputText, {
                    modelValue: storageMask.value,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((storageMask).value = $event)),
                    placeholder: "Search",
                    size: "34",
                    class: "w-full md:w-24rem"
                  }, null, 8, ["modelValue"])
                ]),
                _createVNode(_component_Listbox, {
                  "data-cy": "dataBrowseSelect__selectStorage",
                  modelValue: storageIdDropdown.value,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((storageIdDropdown).value = $event)),
                  options: storageMask.value.length > 0 ? grouppedVolumes.value.filter(f => f && f.name.indexOf(storageMask.value) > -1) : grouppedVolumes.value,
                  optionLabel: "name",
                  optionValue: "id",
                  onChange: changeStorageId,
                  virtualScrollerOptions: { itemSize: 38 },
                  class: "w-full md:w-24rem",
                  listStyle: "height:650px",
                  loading: _unref(getVolumesObject).loading.value
                }, null, 8, ["modelValue", "options", "loading"])
              ]),
              _createElementVNode("div", _hoisted_4, [
                _cache[11] || (_cache[11] = _createElementVNode("h6", null, "Select Volume", -1)),
                _createElementVNode("span", _hoisted_5, [
                  _cache[10] || (_cache[10] = _createElementVNode("i", { class: "pi pi-search" }, null, -1)),
                  _createVNode(_component_InputText, {
                    modelValue: volumeMask.value,
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((volumeMask).value = $event)),
                    placeholder: "Search",
                    size: "34",
                    class: "w-full md:w-24rem"
                  }, null, 8, ["modelValue"])
                ]),
                _createVNode(_component_Listbox, {
                  "data-cy": "dataBrowseSelect__selectVolume",
                  name: "outputVolumeMountAlias",
                  label: "Output volume",
                  modelValue: volumeMountAliasDropdown.value,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((volumeMountAliasDropdown).value = $event)),
                  options: volumeMask.value.length > 0 ? storageVolumes.value.filter(f => f && f.name.indexOf(volumeMask.value) > -1) : storageVolumes.value,
                  optionLabel: "name",
                  optionValue: "mountAlias",
                  onChange: changeVolumeMountAlias,
                  virtualScrollerOptions: { itemSize: 38 },
                  class: "w-full md:w-24rem",
                  listStyle: "height:650px",
                  placeholder: "Select volume to browse"
                }, null, 8, ["modelValue", "options"])
              ])
            ]))
          : _createCommentVNode("", true),
        (volumeMountAliasDropdown.value)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
              _createElementVNode("h4", null, [
                _createElementVNode("a", {
                  onClick: _cache[5] || (_cache[5] = ($event: any) => (clearVolumeMountAlias())),
                  title: "Change volume",
                  class: "cursor-pointer"
                }, [
                  _cache[12] || (_cache[12] = _createElementVNode("i", { class: "fa fa-circle-chevron-left" }, null, -1)),
                  _createTextVNode(" " + _toDisplayString(grouppedVolumes.value.find(f => f.volumes.find((v) => v.mountAlias === volumeMountAliasDropdown.value)) && storageVolumes.value.find(f =>
              f.mountAlias === volumeMountAliasDropdown.value) ?
            grouppedVolumes.value.find(f => f.volumes.find((v) => v.mountAlias === volumeMountAliasDropdown.value))?.name + '/' +
            storageVolumes.value.find(f => f.mountAlias === volumeMountAliasDropdown.value)?.name : 'Select File'), 1)
                ])
              ]),
              (dataBrowserErrors.value.length > 0)
                ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                    _cache[13] || (_cache[13] = _createElementVNode("p", null, "Errors", -1)),
                    _createElementVNode("ul", null, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(dataBrowserErrors.value, (error, i) => {
                        return (_openBlock(), _createElementBlock("li", { key: i }, _toDisplayString(error), 1))
                      }), 128))
                    ])
                  ]))
                : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                    (!dataBrowserReady.value)
                      ? (_openBlock(), _createElementBlock("ul", _hoisted_7, [
                          (!_unref(dataBrowserStatus).dataBrowserServiceCreated)
                            ? (_openBlock(), _createElementBlock("li", _hoisted_8, _cache[14] || (_cache[14] = [
                                _createElementVNode("i", { class: "fa fa-exclamation-triangle" }, null, -1),
                                _createTextVNode(" Data Browser service not available. Contact your administrator. ")
                              ])))
                            : _createCommentVNode("", true),
                          (!_unref(dataBrowserStatus).dataBrowserServiceReady)
                            ? (_openBlock(), _createElementBlock("li", _hoisted_9, _cache[15] || (_cache[15] = [
                                _createElementVNode("i", { class: "fa fa-exclamation-triangle" }, null, -1),
                                _createTextVNode(" Data Browser service not ready yet. Wait or contact your administrator. ")
                              ])))
                            : _createCommentVNode("", true),
                          (!_unref(dataBrowserStatus).taskVolumeCreated || !_unref(dataBrowserStatus).taskVolumeReady)
                            ? (_openBlock(), _createElementBlock("li", _hoisted_10, _cache[16] || (_cache[16] = [
                                _createElementVNode("i", { class: "fa fa-spin fa-spinner" }, null, -1),
                                _createTextVNode(" Volume is mounting... ")
                              ])))
                            : _createCommentVNode("", true)
                        ]))
                      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                          _createElementVNode("p", null, [
                            _createVNode(_component_Breadcrumb, {
                              home: home.value,
                              model: items.value
                            }, {
                              item: _withCtx(({ item }) => [
                                _createElementVNode("a", {
                                  class: "cursor-pointer",
                                  onClick: item.command
                                }, [
                                  (item.icon)
                                    ? (_openBlock(), _createElementBlock("i", {
                                        key: 0,
                                        class: _normalizeClass(item.icon)
                                      }, null, 2))
                                    : _createCommentVNode("", true),
                                  _createTextVNode(" " + _toDisplayString(item.label), 1)
                                ], 8, _hoisted_11)
                              ]),
                              _: 1
                            }, 8, ["home", "model"])
                          ]),
                          _createVNode(_component_TreeTable, {
                            style: {"max-height":"600px"},
                            value: nodes.value,
                            lazy: true,
                            paginator: true,
                            rows: limit.value,
                            first: offset.value,
                            loading: loading.value,
                            onPage: onPage,
                            totalRecords: totalRecords.value,
                            class: _normalizeClass(`p-treetable-small`),
                            onSort: sortBrowser,
                            removableSort: "",
                            rowsPerPageOptions: [5, 10, 25, 50, 100],
                            "sort-field": query.value.sort.column,
                            "sort-order": query.value.sort.order === _unref(DataBrowserSortOption).Asc ? 1 : -1
                          }, {
                            header: _withCtx(() => [
                              _createElementVNode("div", _hoisted_12, [
                                _createElementVNode("div", _hoisted_13, [
                                  _cache[17] || (_cache[17] = _createElementVNode("i", { class: "pi pi-search" }, null, -1)),
                                  _createVNode(_component_InputText, {
                                    modelValue: search.value,
                                    "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((search).value = $event)),
                                    placeholder: "Regexp Search"
                                  }, null, 8, ["modelValue"])
                                ])
                              ])
                            ]),
                            default: _withCtx(() => [
                              _createVNode(_component_Column, { headerStyle: "width: 6rem;" }, {
                                body: _withCtx(({ node }) => [
                                  _createElementVNode("div", _hoisted_14, [
                                    (isLeaf(node))
                                      ? (_openBlock(), _createBlock(_component_Button, {
                                          key: 0,
                                          disabled: isSelected(node),
                                          type: "button",
                                          severity: isSelected(node) ? 'secondary' : 'primary',
                                          label: isSelected(node) ? 'Selected' : 'Select',
                                          size: "small",
                                          onClick: ($event: any) => (selectFile(node))
                                        }, null, 8, ["disabled", "severity", "label", "onClick"]))
                                      : _createCommentVNode("", true)
                                  ])
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_Column, { headerStyle: "width: 2rem" }, {
                                body: _withCtx(({ node }) => [
                                  (isSelected(node))
                                    ? (_openBlock(), _createElementBlock("i", _hoisted_15))
                                    : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                                        (node.data.isCurrent)
                                          ? (_openBlock(), _createElementBlock("i", _hoisted_16))
                                          : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                                              (node.leaf)
                                                ? (_openBlock(), _createElementBlock("i", _hoisted_17))
                                                : (_openBlock(), _createElementBlock("i", _hoisted_18))
                                            ], 64))
                                      ], 64))
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_Column, {
                                field: _unref(DataBrowserSortColumn).Name,
                                header: "Name",
                                sortable: ""
                              }, {
                                body: _withCtx(({ node }) => [
                                  (node.leaf || node.data.isCurrent)
                                    ? (_openBlock(), _createElementBlock("span", {
                                        key: 0,
                                        innerHTML: formatName(node, search.value),
                                        "data-cy": "dataBrowseSelect__nameFile"
                                      }, null, 8, _hoisted_19))
                                    : (_openBlock(), _createElementBlock("a", {
                                        key: 1,
                                        class: "cursor-pointer",
                                        onClick: ($event: any) => (onExpand(node))
                                      }, [
                                        _createElementVNode("span", {
                                          innerHTML: formatName(node, search.value),
                                          "data-cy": "dataBrowseSelect__nameDir"
                                        }, null, 8, _hoisted_21)
                                      ], 8, _hoisted_20))
                                ]),
                                _: 1
                              }, 8, ["field"]),
                              _createVNode(_component_Column, {
                                field: "size",
                                header: "Size",
                                headerStyle: "width: 8rem"
                              }),
                              _createVNode(_component_Column, {
                                field: _unref(DataBrowserSortColumn).Extension,
                                header: "Type",
                                sortable: "",
                                headerStyle: "width: 8rem"
                              }, {
                                body: _withCtx(({ node }) => [
                                  _createTextVNode(_toDisplayString(node.data.type), 1)
                                ]),
                                _: 1
                              }, 8, ["field"])
                            ]),
                            _: 1
                          }, 8, ["value", "rows", "first", "loading", "totalRecords", "sort-field", "sort-order"])
                        ], 64))
                  ], 64))
            ], 64))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["visible", "header"])
  ], 64))
}
}

})