<template>
  <Button data-cy="dataBrowseSelect__browseFiles" icon="fa-solid fa-folder-open" @click="visible = true" t-tooltip.bottom="Browse files"
    :label="props.buttonText || ''"></Button>
  <Dialog v-model:visible="visible" modal :header="title && title.length > 0 ? title : 'Select file'"
    :style="{ width: '800px' }">
    <div class="grid col-12 formgrid" v-if="!volumeMountAliasDropdown">
      <div class="field col-12 md:col-6">
        <h6>Select storage</h6>
        <span class="p-input-icon-left">
          <i class="pi pi-search" />
          <InputText v-model="storageMask" placeholder="Search" size="34" class="w-full md:w-24rem" />
        </span>
        <Listbox data-cy="dataBrowseSelect__selectStorage" v-model="storageIdDropdown"
          :options="storageMask.length > 0 ? grouppedVolumes.filter(f => f && f.name.indexOf(storageMask) > -1) : grouppedVolumes"
          optionLabel="name" optionValue="id" @change="changeStorageId" :virtualScrollerOptions="{ itemSize: 38 }"
          class="w-full md:w-24rem" listStyle="height:650px" :loading="getVolumesObject.loading.value" />
      </div>
      <div class="field col-12 md:col-6">
        <h6>Select Volume</h6>
        <span class="p-input-icon-left">
          <i class="pi pi-search" />
          <InputText v-model="volumeMask" placeholder="Search" size="34" class="w-full md:w-24rem" />
        </span>
        <Listbox data-cy="dataBrowseSelect__selectVolume" name="outputVolumeMountAlias" label="Output volume" v-model="volumeMountAliasDropdown"
          :options="volumeMask.length > 0 ? storageVolumes.filter(f => f && f.name.indexOf(volumeMask) > -1) : storageVolumes"
          optionLabel="name" optionValue="mountAlias" @change="changeVolumeMountAlias" :virtualScrollerOptions="{ itemSize: 38 }"
          class="w-full md:w-24rem" listStyle="height:650px" placeholder="Select volume to browse" />

      </div>
    </div>
    <template v-if="volumeMountAliasDropdown">
      <h4><a @click="clearVolumeMountAlias()" title="Change volume" class="cursor-pointer">
          <i class="fa fa-circle-chevron-left"></i>
          {{
            grouppedVolumes.find(f => f.volumes.find((v: Volume) => v.mountAlias === volumeMountAliasDropdown)) && storageVolumes.find(f =>
              f.mountAlias === volumeMountAliasDropdown) ?
            grouppedVolumes.find(f => f.volumes.find((v: Volume) => v.mountAlias === volumeMountAliasDropdown))?.name + '/' +
            storageVolumes.find(f => f.mountAlias === volumeMountAliasDropdown)?.name : 'Select File'
          }}</a></h4>
      <template v-if="dataBrowserErrors.length > 0">
        <div>
          <p>Errors</p>
          <ul>
            <li v-for="(error, i) of dataBrowserErrors" v-bind:key="i">{{ error }}</li>
          </ul>
        </div>
      </template>
      <template v-else>
        <template v-if="!dataBrowserReady">
          <ul class="pl-0" style="list-style-type: none;">
            <li v-if="!dataBrowserStatus.dataBrowserServiceCreated">
              <i class="fa fa-exclamation-triangle"></i> Data Browser service not available. Contact your administrator.
            </li>
            <li v-if="!dataBrowserStatus.dataBrowserServiceReady">
              <i class="fa fa-exclamation-triangle"></i> Data Browser service not ready yet. Wait or contact your administrator.
            </li>
            <li v-if="!dataBrowserStatus.taskVolumeCreated || !dataBrowserStatus.taskVolumeReady">
              <i class="fa fa-spin fa-spinner"></i> Volume is mounting...
            </li>
          </ul>
        </template>
        <template v-else>
          <p>
            <Breadcrumb :home="home" :model="items">
              <template #item="{ item }">
                  <a class="cursor-pointer" @click="item.command">
                    <i :class="item.icon" v-if="item.icon"></i>
                    {{ item.label }}
                  </a>
              </template>
            </Breadcrumb>
          </p>
          <TreeTable style="max-height:600px;" :value="nodes" :lazy="true" :paginator="true" :rows="limit" :first="offset"
            :loading="loading" @page="onPage" :totalRecords="totalRecords" :class="`p-treetable-small`"
            @sort="sortBrowser" removableSort :rowsPerPageOptions="[5, 10, 25, 50, 100]" :sort-field="query.sort.column"
            :sort-order="query.sort.order === DataBrowserSortOption.Asc ? 1 : -1">
            <template #header>
              <div class="text-right">
                <div class="p-input-icon-left">
                  <i class="pi pi-search"></i>
                  <InputText v-model="search" placeholder="Regexp Search" />
                </div>
              </div>
            </template>
            <Column headerStyle="width: 6rem;">
              <template #body="{ node }">
                <div class="flex flex-wrap gap-2 justify-content-end">
                  <Button v-if="isLeaf(node)" :disabled="isSelected(node)" type="button" :severity="isSelected(node) ? 'secondary' : 'primary'" :label="isSelected(node) ? 'Selected' : 'Select'" size="small" @click="selectFile(node)" />
                </div>
              </template>
            </Column>
            <Column headerStyle="width: 2rem">
              <template #body="{ node }">
                <template v-if="isSelected(node)">
                  <i class="pi pi-circle-fill" style="color: var(--primary-color)"></i>
                </template>
                <template v-else>
                  <template v-if="node.data.isCurrent">
                    <i class="pi pi-folder-open"></i>
                  </template>
                  <template v-else>
                    <i v-if="node.leaf" class="pi pi-file"></i>
                    <i v-else class="pi pi-folder"></i>
                  </template>
                </template>
              </template>
            </Column>
            <Column :field="DataBrowserSortColumn.Name" header="Name" sortable>
              <template #body="{ node }">
                <template v-if="node.leaf || node.data.isCurrent">
                  <span v-html="formatName(node, search)" data-cy="dataBrowseSelect__nameFile"></span>
                </template>
                <template v-else>
                  <a class="cursor-pointer" @click="onExpand(node)">
                  <span v-html="formatName(node, search)" data-cy="dataBrowseSelect__nameDir"></span></a>
                </template>
              </template>
            </Column>

            <Column field="size" header="Size" headerStyle="width: 8rem"></Column>
            <Column :field="DataBrowserSortColumn.Extension" header="Type" sortable headerStyle="width: 8rem">
              <template #body="{ node }">
                {{ node.data.type }}
              </template>
            </Column>
          </TreeTable>
        </template>
      </template>
    </template>

  </Dialog>
</template>

<style>
.p-treetable .p-treetable-tbody>tr>td {
  padding: .5rem .5rem;
}

.p-treetable .p-treetable-tbody>tr>td button {
  padding: .2rem .5rem;
}

:deep(.p-treetable .p-treetable-tbody > tr:nth-child(even)) {
  background: #fafafa;
}
</style>

<script lang="ts" setup>

// imports

import { ref, watch } from 'vue'
import { useConfig } from '@/composables/useConfig'
import { useDataBrowser } from '@/composables/useDataBrowser'
import { DataBrowserEntity, DataBrowserEntityType, DataBrowserSortOption, DataBrowserSortColumn, Volume } from '@/gql/graphql'
import { DropdownChangeEvent } from 'primevue/dropdown';
import type { Ref } from 'vue'
import { MenuItem } from 'primevue/menuitem';
import { TreeTablePageEvent, TreeTableSortEvent } from 'primevue/treetable';
import debounce from 'lodash.debounce'
import prettyBytes from 'pretty-bytes';
import { useVolumes } from '@/composables/useVolumes';

const { getVolumes } = useVolumes()
const getVolumesObject = getVolumes()
const { volumes, refetch: refetchVolumes } = getVolumesObject

// custom classes

type DataBrowserTreeNode = {
  key: number
  leaf: boolean
  data: {
    name: string,
    size: string,
    type: string | undefined,
    path: string,
    relativePath: string,
    isCurrent: boolean,
  }
}

/**
 * Object selected will contain the following: {volumeMountAlias: 'string', path: 'string'}, according to this value in props, path should be pre-selected in DBS
 */
const props = defineProps({
  selected: {
    type: Object,
    required: false
  },
  title: {
    type: String,
    required: false,
    default: ''
  },
  buttonText: {
    type: String,
    required: false
  },
  preselectedVolumeMountAliases: {
    type: Array<{ mountAlias: String, name: String }>,
    required: false
  },
  leaf: {
    type: String,
    required: false,
    default: ''
  },
})

const { getDataBrowserStatus, getDataBrowserList } = useDataBrowser()
const { getConfig } = useConfig()

const grouppedVolumes: Ref<Storage[]> = ref([])

const nodes = ref()
const visible = ref(false)
const volumeMountAliasDropdown: Ref<string | undefined> = ref(undefined)
const storageIdDropdown: Ref<string | undefined> = ref(undefined)
const volumeMountAlias: Ref<string> = ref('')
const storageVolumes: Ref<Volume[]> = ref([])
const dataBrowserReady = ref(false)
const dataBrowserErrors: Ref<string[]> = ref([])
const loading = ref(false)
const limit = ref(50)
const offset = ref(0)
const totalRecords = ref(0)
const dirPath = ref('')
const search = ref('')
const query = ref({
  limit: limit.value,
  offset: offset.value,
  dirPath: '',
  search: '',
  volumeMountAlias: '',
  sort: {
    column: DataBrowserSortColumn.Name,
    order: DataBrowserSortOption.Asc
  }
})
const home: Ref<MenuItem> = ref({
  icon: 'pi pi-home',
  // label: 'root',
  command: () => {
    dataBrowserListReload('')
  },
});
const items: Ref<MenuItem[]> = ref([]);

const storageMask = ref('')
const volumeMask = ref('')

const emit = defineEmits(['selectFile'])

function selectFile(node: DataBrowserTreeNode) {
  emit('selectFile', {
    volumeMountAlias: volumeMountAlias.value,
    name: node.data.name,
    dirPath: dirPath.value,
    path: node.data.relativePath,
    limit: limit.value,
    offset: offset.value,
    totalRecords: totalRecords.value,
  })
  visible.value = false
}

// query filter builder

const selectedVolume = ref('')
const setBrowserVolume = (_volumeMountAlias: string) => {
  volumeMountAlias.value = _volumeMountAlias
  selectedVolume.value = _volumeMountAlias
}

const showBrowseSelect = () => {
  visible.value = true
}

defineExpose({
  setBrowserVolume,
  showBrowseSelect,
})

const clearVolumeMountAlias = () => {
  volumeMountAliasDropdown.value = undefined
  storageMask.value = ''
  volumeMask.value = ''
}

const isLeaf = (node: DataBrowserTreeNode) => {
  if (props.leaf === '') {
    return true
  }
  else if (props.leaf === 'dir') {
    return !node.leaf
  }
  else if (props.leaf === 'file') {
    return node.leaf
  }
  else {
    return false
  }
}

const formatName = (node: DataBrowserTreeNode, search: string) => {
  const text = node.data.name
  let html = text
  if (search !== "") {
    try {
      new RegExp(search, "ig")
    } catch {
      search = search.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
    }

    const matches = text.matchAll(new RegExp(search, "ig"));
    html = ""
    let stringCursor = 0

    for (const match of matches) {
      if (match.index === undefined) continue
      // text from last position to this match
      html += text.slice(stringCursor, match.index)
      // match in bold
      html += `<strong>${match[0]}</strong>`
      // set cursor to end of this match
      stringCursor = match.index + match[0].length
    }
    // add rest of text
    html += text.slice(stringCursor)
  }
  return html + " "
}
const isSelected = (node: DataBrowserTreeNode) => {
  return props.selected?.path === node.data.relativePath && props.selected?.volumeMountAlias === volumeMountAlias.value
}

// composable utils

const {
  status: dataBrowserStatus,
  error: dataBrowserStatusError,
  load: dataBrowserStatusLoad,
  refetch: dataBrowserStatusRefetch
} = getDataBrowserStatus(volumeMountAlias)

const {
  list: dataBrowserList,
  error: dataBrowserListError,
  load: dataBrowserListLoad,
  refetch: dataBrowserListRefetch
} = getDataBrowserList(query)

// config

const config = getConfig();

// watches 

watch(visible, () => {
  // when user opens modal 
  if (visible.value) {
    refetchVolumes()
    if (props.selected && props.selected.volumeMountAlias?.length > 0) {
      // and file/dir is already selected - set selected volume and browse to its parent directory
      dirPath.value = props.selected.dirPath

      limit.value = props.selected.limit
      offset.value = props.selected.offset
      totalRecords.value = props.selected.totalRecords
      setProperQueryLimitOffset()

      let storage = grouppedVolumes.value.find(s => s.volumes.find((v: Volume) => v.mountAlias === props.selected?.volumeMountAlias))
      storageIdDropdown.value = storage?.mountAlias
      if (storage) {
        storage.volumes.sort((a: any, b: any) => { return a.name - b.name });
        storageVolumes.value = storage.volumes
      }
      volumeMountAliasDropdown.value = props.selected.volumeMountAlias
    }
    else if (selectedVolume.value != '') {
      let storage = grouppedVolumes.value.find(s => s.volumes.find((v: Volume) => v.mountAlias === selectedVolume.value))
      storageIdDropdown.value = storage?.mountAlias
      if (storage) {
        storage.volumes.sort((a: any, b: any) => { return a.name - b.name });
        storageVolumes.value = storage.volumes
      }
      dirPath.value = ""
      volumeMountAliasDropdown.value = selectedVolume.value
    }
    else {
      // and no file/dir is selected - set browse to root
      dirPath.value = ""
    }
    if (volumeMountAliasDropdown.value) dataBrowserStatusReload()
  }
})

watch(volumes, (newValue) => {
  // Create a map to store unique storages with their volumes
  let storageMap = new Map();

  newValue
    .filter((volume) => {
      if (props.preselectedVolumeMountAliases) {
        return props.preselectedVolumeMountAliases?.map(e => e.mountAlias).includes(volume.mountAlias)
      } else { return true }
    })
    .forEach(volume => {
      // Check if storageMap already contains the storage id
      let storage = storageMap.get(volume?.storage?.id);

      // If it doesn't contain, create a new storage object
      if (!storage) {
        storage = {
          id: volume?.storage?.id,
          name: volume?.storage?.name,
          volumes: []
        };
        storageMap.set(volume?.storage?.id, storage);
      }

      // Push the current volume to the storage's volumes array
      storage.volumes.push({
        mountAlias: volume.mountAlias,
        name: volume.name,
        volumeStatusAlias: volume.volumeStatus?.alias,
      });
    });

  // Convert map values to an array and return
  grouppedVolumes.value = Array.from(storageMap.values());
})

const changeStorageId = (event: DropdownChangeEvent) => {
  if (!event.value) {
    if (storageVolumes.value && storageVolumes.value.length > 0) {
      storageIdDropdown.value = storageVolumes.value[0]?.storage?.id
      return
    }
  }
  let _volume = volumes.value.filter(item => item?.storage?.id === event.value)
  _volume.sort((a, b) => { return a.name - b.name });
  storageVolumes.value = _volume
}

const changeVolumeMountAlias = (event: DropdownChangeEvent) => {
  let _volume = volumes.value.find(item => item.mountAlias === event.value)

  volumeMountAlias.value = event.value
  volumeMountAlias.value = _volume?.mountAlias || event.value
  query.value.volumeMountAlias = event.value
  dirPath.value = ""
  query.value.dirPath = ""
  dataBrowserStatusReload()
  dataBrowserListReload()
}

watch(volumeMountAliasDropdown, (newValue) => {
  if (newValue) {
    volumeMountAlias.value = newValue
    let _volume = volumes.value.find(item => item.mountAlias === newValue)
    volumeMountAlias.value = _volume?.mountAlias || newValue.toString()
    query.value.volumeMountAlias = newValue
  }
})

watch(dirPath, (newValue) => {
  query.value.dirPath = dirPath.value
  items.value = newValue?.replace(/^\/(.+)$/, '$1').split('/').reduce((acc, current) => {
    acc.relativePath.push(current)
    const relativePath = acc.relativePath.join("/")
    acc.items.push({
      label: current,
      relativePath,
      command: () => {
        dataBrowserListReload(relativePath)
      }
    })
    return acc
  }, {
    relativePath: [] as String[],
    items: [] as MenuItem[]
  }).items
})

watch(dataBrowserStatus, (newValue) => {
  dataBrowserReady.value = newValue.dataBrowserServiceCreated &&
    newValue.dataBrowserServiceReady &&
    newValue.taskVolumeCreated &&
    newValue.taskVolumeReady
  if (dataBrowserReady.value) {
    dataBrowserListReload()
  }
})

watch(dataBrowserStatusError, (newValue) => {
  dataBrowserErrors.value = []
  if (newValue !== null) {
    newValue.graphQLErrors.forEach(error => {
      dataBrowserErrors.value = [...dataBrowserErrors.value, error.message]
    });
  }
})

watch(dataBrowserList, (newValue) => {
  if (newValue.data) {
    nodes.value = mapDataBrowserList(newValue.data)
    totalRecords.value = newValue.pageInfo.total + 1
  }
})

watch(search, debounce(() => {
  query.value.search = search.value
  dataBrowserListReload();
}, 250))

// utils

const mapDataBrowserList = (list: DataBrowserEntity[]) => {
  if (query.value.offset === 0) list.unshift({
    name: "",
    path: "",
    type: DataBrowserEntityType.Directory
  })
  return list.map((entity, i) => {
    let relativePath = dirPath.value
    if (entity.name.length) {
      relativePath = relativePath.length > 0 ? [dirPath.value, entity.name].join("/") : entity.name
    }
    return {
      key: query.value.offset + i + (query.value.offset === 0 ? 0 : 1),
      data: {
        name: entity.name.length ? entity.name : "<i>(current directory)</i>",
        isCurrent: entity.name.length ? false : true,
        size: entity.type === DataBrowserEntityType.File ? prettyBytes(entity.attributes!.size) : "",
        type: entity.attributes?.extension,
        relativePath,
      },
      leaf: entity.type === DataBrowserEntityType.File
    } as DataBrowserTreeNode
  })
}


const dataBrowserStatusReload = () => {
  dataBrowserStatusLoad() || dataBrowserStatusRefetch()
}

const dataBrowserListReload = (dirPathToChange: string = dirPath.value) => {
  dirPath.value = dirPathToChange
  query.value.dirPath = dirPathToChange

  dataBrowserListLoad() || dataBrowserListRefetch()
}

// events
const sortBrowser = (event: TreeTableSortEvent) => {
  query.value.sort.column = event.sortField as DataBrowserSortColumn
  query.value.sort.order = !event.sortOrder ? DataBrowserSortOption.Asc : (
    event.sortOrder > 0 ? DataBrowserSortOption.Asc : DataBrowserSortOption.Desc
  )
  dataBrowserListReload()
}
const setProperQueryLimitOffset = () => {
  query.value.limit = limit.value
  query.value.offset = offset.value === 0 ? offset.value : offset.value - 1
}

const onExpand = (node: DataBrowserTreeNode) => {
  dirPath.value = node.data.relativePath
  dataBrowserListReload()
}
const onPage = (event: TreeTablePageEvent) => {
  limit.value = event.rows
  offset.value = event.first
  setProperQueryLimitOffset()
  dataBrowserListReload()
}
</script>
