<script setup lang="ts">
/**
 * Load validation references, router, vue
 */
import { Form } from 'vee-validate';
import { Ref, nextTick, ref, watch } from 'vue';

import { usePresets } from '@/composables/usePresets';
import CameraDefPresetForm from '@/components/Admin/Settings/Presets/forms/CameraDefPresetForm.vue';
import LidarDefPresetForm from '@/components/Admin/Settings/Presets/forms/LidarDefPresetForm.vue';
import TrajectoryDefPresetForm from '@/components/Admin/Settings/Presets/forms/TrajectoryDefPresetForm.vue';

import { Preset, PresetTypesEnum } from '@/gql/graphql';
import { PRESET_TYPES } from '@/helpers/constants';
import { useRouter } from 'vue-router';
import { useToast } from 'primevue/usetoast';

// Define the event emitter
const emit = defineEmits(['next', 'prevPage', 'onCancel'])

const onCancel = () => {
  emit('onCancel', router.currentRoute.value.name)
}

const stepBack = () => {
  emit('prevPage', router.currentRoute.value.name)
}

const router = useRouter()

const update = false//ref(router.currentRoute.value.path.indexOf('/edit/') > -1)

const props = defineProps({
  formData: {
    type: Object,
    required: true
  }
})

const PRESETS_EMPTY_VALUE = 'New empty preset'
const { getPresets } = usePresets()

// Camera preset
const { presets: cameraPresetsDb, refetch: refetchCameraPresetsDb } = getPresets({
  filter: {
    fields: [
      {
        type: "equals",
        name: "presetTypeAlias",
        value: PRESET_TYPES.CAMERA_DEF
      }
    ]
  }
})
const cameraPresets = ref<Preset[]>([])
watch(cameraPresetsDb, () => {
  if (cameraPresetsDb?.value) {
    cameraPresets.value = [
      {
        key: PRESETS_EMPTY_VALUE,
        presetType: {
          alias: PresetTypesEnum.CameraDef,
          presets: [],
        },
        value: {
          CameraDef: null
        }
      },
      ...cameraPresetsDb.value
    ]
  }
})

// LiDAR preset

const { presets: lidarPresetsDb, refetch: refetchLidarPresetsDb } = getPresets({
  filter: {
    fields: [
      {
        type: "equals",
        name: "presetTypeAlias",
        value: PRESET_TYPES.LIDAR_DEF
      }
    ]
  }
})
const lidarPresets = ref<Preset[]>([])
watch(lidarPresetsDb, () => {
  if (lidarPresets?.value) {
    lidarPresets.value = [
      {
        key: PRESETS_EMPTY_VALUE,
        presetType: {
          alias: PresetTypesEnum.LidarDef,
          presets: [],
        },
        value: {
          LidarDef: null
        },
      }, ...lidarPresetsDb.value
    ]
  }
})

// Trajectory preset
const { presets: trajectoryPresetsDb, refetch: refetchTrajectoryPresetsDb } = getPresets({
  filter: {
    fields: [
      {
        type: "equals",
        name: "presetTypeAlias",
        value: PRESET_TYPES.TRAJECTORY_DEF
      }
    ]
  }
})
const trajectoryPresets = ref<Preset[]>([])
watch(trajectoryPresetsDb, () => {
  if (trajectoryPresets?.value) {
    trajectoryPresets.value = [
      {
        key: PRESETS_EMPTY_VALUE,
        presetType: {
          alias: PresetTypesEnum.TrajectoryDef,
          presets: [],
        },
        value: {
          TrajectoryDef: null
        }
      },
      ...trajectoryPresetsDb.value
    ]
  }
})

const CameraDefPresetAlias: Ref<string | null | undefined> = ref(
  props.formData?.populationConfig?.CameraDefPresetAlias ?? null
)
const CameraDef: Ref<any> = ref(
  {
    CameraDef: props.formData?.populationConfig?.CameraDef ?? null
  }
)
const cameraDefPresetForm = ref(null)
watch(cameraDefPresetForm, () => {
  if (cameraDefPresetForm?.value) {
    cameraDefPresetForm?.value?.setReadonly(CameraDefPresetAlias.value != PRESETS_EMPTY_VALUE)
  }
})

const LidarDefPresetAlias: Ref<string | null | undefined> = ref(
  props.formData?.populationConfig?.LidarDefPresetAlias ?? null
)
const LidarDef: Ref<any> = ref(
  {
    LidarDef: props.formData?.populationConfig?.LidarDef ?? null
  }
)
const lidarDefPresetForm = ref(null)
watch(lidarDefPresetForm, () => {
  if (lidarDefPresetForm?.value) {
    lidarDefPresetForm?.value?.setReadonly(LidarDefPresetAlias.value != PRESETS_EMPTY_VALUE)
  }
})

const TrajectoryDefPresetAlias: Ref<string | null | undefined> = ref(
  props.formData?.populationConfig?.TrajectoryDefPresetAlias ?? null
)
const TrajectoryDef: Ref<any> = ref(
  {
    TrajectoryDef: props.formData?.populationConfig?.TrajectoryDef ?? null
  }
)
const trajectoryDefPresetForm = ref(null)
watch(trajectoryDefPresetForm, () => {
  if (trajectoryDefPresetForm?.value) {
    trajectoryDefPresetForm?.value?.setReadonly(TrajectoryDefPresetAlias.value != PRESETS_EMPTY_VALUE)
  }
})

const showCameraPreset = ref(props.formData?.populationConfig?.CameraDefPresetAlias ? true : false)
const showLidarPreset = ref(props.formData?.populationConfig?.LidarDefPresetAlias ? true : false)
const showTrajectoryPreset = ref(props.formData?.populationConfig?.TrajectoryDefPresetAlias ? true : false)

const onPresetCameraValueChange = (value: any) => {
  if (value && value.value) {
    showCameraPreset.value = false

    const presetValue = Object.assign({}, cameraPresets.value.find(item => item.key === value.value))
    CameraDef.value = presetValue.value

    nextTick(() => {
      showCameraPreset.value = true
      setTimeout(() => cameraDefPresetForm?.value?.setReadonly(CameraDefPresetAlias.value != PRESETS_EMPTY_VALUE), 10)
    })
  }
}

const onPresetLidarValueChange = (value: any) => {
  if (value && value.value) {
    showLidarPreset.value = false

    const presetValue = Object.assign({}, lidarPresets.value.find(item => item.key === value.value))
    LidarDef.value = presetValue.value

    nextTick(() => {
      showLidarPreset.value = true
    })
  }
}

const onPresetTrajectoryValueChange = (value: any) => {
  if (value && value.value) {
    showTrajectoryPreset.value = false

    const presetValue = Object.assign({}, trajectoryPresets.value.find(item => item.key === value.value))
    TrajectoryDef.value = presetValue.value

    nextTick(() => {
      showTrajectoryPreset.value = true
    })
  }
}

// Function
const onSubmit = (values: any) => {
  //Object.assign({CameraDefPresetAlias: CameraDefPresetAlias.value}, CameraDef.value )

  if (cameraCheck.value && !CameraDef?.value?.CameraDef) {
    toast.add({severity:'error', summary: 'Camera definition missing', detail:'Please fill in or select camera definition', life: 3000})
    return
  }

  if (lidarCheck.value && !LidarDef?.value?.LidarDef) {
    toast.add({severity:'error', summary: 'LiDAR definition missing', detail:'Please fill in or select lidar definition', life: 3000})
    return
  }

  if (trajectoryCheck.value && !TrajectoryDef?.value?.TrajectoryDef) {
    toast.add({severity:'error', summary: 'Trajectory definition missing', detail:'Please fill in or select trajectory definition', life: 3000})
    return
  }

  let _values = JSON.parse(JSON.stringify(values))
  _values.Camera = cameraCheck
  _values.CameraDef = CameraDef.value.CameraDef
  _values.CameraDefPresetAlias = CameraDefPresetAlias.value
  _values.Lidar = lidarCheck
  _values.LidarDef = LidarDef.value.LidarDef
  _values.LidarDefPresetAlias = LidarDefPresetAlias.value
  _values.Trajectory = trajectoryCheck
  _values.TrajectoryDef = TrajectoryDef.value.TrajectoryDef
  _values.TrajectoryDefPresetAlias = TrajectoryDefPresetAlias.value
  emit('next', _values,  router.currentRoute.value.name);
}

const collapsed: Ref<any> = ref(
  {
    camera: true,
    lidar: true,
    trajectory: true
  }
)

const cameraCheck = ref(props.formData?.populationConfig?.Camera ? true : false)
const lidarCheck = ref(props.formData?.populationConfig?.Lidar ? true : false)
const trajectoryCheck = ref(props.formData?.populationConfig?.Trajectory ? true : false)

const toggle = (id: string, event: any) => {
  Object.keys(collapsed.value).forEach(element => {
    collapsed.value[element] = true
  })
  collapsed.value[id] = event.value
}

const { createPreset } = usePresets()
const toast = useToast()

const savePreset = async (name: string, data: object, type: PRESET_TYPES) => {
  try {
    let _value = JSON.parse(JSON.stringify(data))

    let res = await createPreset({
      key: name,
      presetTypeAlias: type,
      value: {
        [type]: _value
      },
      presetType: {
        __typename: undefined,
        alias: '',
        attributes: undefined,
        name: undefined
      }
    })

    if(res.success){
      toast.add({ severity: 'success', summary: 'Success', detail: 'Succesfully created', life: 3000 })
      switch (type) {
      case PRESET_TYPES.CAMERA_DEF:
        await refetchCameraPresetsDb()
        CameraDefPresetAlias.value = name
        break
      case PRESET_TYPES.LIDAR_DEF:
        await refetchLidarPresetsDb()
        LidarDefPresetAlias.value = name
        break
      case PRESET_TYPES.TRAJECTORY_DEF:
        await refetchTrajectoryPresetsDb()
        TrajectoryDefPresetAlias.value = name
        break
      }
    } else {
      toast.add({severity:'error', summary: 'Error', detail:'Error occured while creating', life: 3000})
    }
  } catch (err) {
    toast.add({severity:'error', summary: 'Error', detail:'Error occured while creating. ' + err.message, life: 3000})
    console.error(err)
  }
}

const onSave = (name: string, type: PRESET_TYPES) => {
  switch (type) {
  case PRESET_TYPES.LIDAR_DEF:
    savePreset(name, LidarDef.value.LidarDef, type)
    break
  case PRESET_TYPES.TRAJECTORY_DEF:
    savePreset(name, TrajectoryDef.value.TrajectoryDef, type)
    break
  case PRESET_TYPES.CAMERA_DEF:
    savePreset(name, CameraDef.value.CameraDef, type)
    break
  }
}

const setPresetValues = (presetType: string, presetAlias: any) => {
  switch (presetType) {
  case 'camera':
    CameraDef.value = Object.assign({}, cameraPresets.value.find(item => item.key === CameraDefPresetAlias.value))?.value
    CameraDefPresetAlias.value = cameraPresets.value.find(item => item.key === PRESETS_EMPTY_VALUE)?.key
    nextTick(() => {
      showCameraPreset.value = true
      setTimeout(() => cameraDefPresetForm?.value?.setReadonly(CameraDefPresetAlias.value != PRESETS_EMPTY_VALUE), 10)
    })
    break
  case 'lidar':
    LidarDef.value = Object.assign({}, lidarPresets.value.find(item => item.key === presetAlias))?.value
    LidarDefPresetAlias.value = lidarPresets.value.find(item => item.key === PRESETS_EMPTY_VALUE)?.key
    nextTick(() => {
      showCameraPreset.value = true
      setTimeout(() => lidarDefPresetForm?.value?.setReadonly(LidarDefPresetAlias.value != PRESETS_EMPTY_VALUE), 10)
    })
    break
  case 'trajectory':
    TrajectoryDef.value = trajectoryPresets.value.find(item => item.key === presetAlias)?.value
    TrajectoryDefPresetAlias.value = trajectoryPresets.value.find(item => item.key === PRESETS_EMPTY_VALUE)?.key
    nextTick(() => {
      showCameraPreset.value = true
      setTimeout(() => trajectoryDefPresetForm?.value?.setReadonly(TrajectoryDefPresetAlias.value != PRESETS_EMPTY_VALUE), 10)
    })
    break
  }
}

</script>

<template>
  <div class="grid">
    <div class="col-12">
      <div class="p-fluid">
        <Form @submit="onSubmit" class="grid p-fluid">
          <div class="col-12 formgrid grid">

            <div class="field col-12 md:col-1" v-if="!update">
              <span class="p-float-label">
                <span class="mlwn-col-input">
                  <Checkbox data-cy="sensorsDefinitions__cameraCheckbox" v-model="cameraCheck" :binary="true" name="camera" class="mt-2" />
                  <label for="camera" class="ml-4 checkbox-label" >Camera</label>
                </span>
              </span>
            </div>

            <div class="field col-12 md:col-3" v-if="!update">
              <span class="p-float-label">
                <span class="mlwn-col-input" v-if="cameraCheck">
                  <Dropdown data-cy="sensorsDefinitions__cameraDropdown" scroll-height="400px"
                            id="presetTypeAliasCamera" class="full-width" v-model="CameraDefPresetAlias"
                            :options="cameraPresets" optionLabel="key" optionValue="key"  placeholder="Select preset"
                            @change="onPresetCameraValueChange"
                            :v-if="!update"
                  />
                  <label for="presetTypeAliasCamera">Select camera preset</label>
                </span>
              </span>
              <span v-if="CameraDefPresetAlias && CameraDefPresetAlias !== PRESETS_EMPTY_VALUE">
                <Button label="Reuse preset as new one" size="small" @click="setPresetValues('camera', CameraDefPresetAlias)"></Button>
              </span>
            </div>

            <div class="field col-12 md:col-1" v-if="!update">
              <span class="p-float-label">
                <span class="mlwn-col-input">
                  <Checkbox data-cy="sensorsDefinitions__lidarCheckbox" v-model="lidarCheck" :binary="true" name="lidar" class="mt-2" />
                  <label for="lidar" class="ml-4 checkbox-label">LiDAR</label>
                </span>
              </span>
            </div>

            <div class="field col-12 md:col-3" v-if="!update">
              <span class="p-float-label">
                <span class="mlwn-col-input" v-if="lidarCheck">
                  <Dropdown data-cy="sensorsDefinitions__lidarDropdown" scroll-height="400px"
                            id="presetTypeAliasLidar" class="full-width" v-model="LidarDefPresetAlias"
                            :options="lidarPresets" optionLabel="key" optionValue="key"  placeholder="Select preset"
                            @change="onPresetLidarValueChange"
                  />
                  <label for="presetTypeAliasLidar">Select LiDAR preset</label>
                </span>
              </span>
              <span v-if="LidarDefPresetAlias && LidarDefPresetAlias !== PRESETS_EMPTY_VALUE">
                <Button label="Reuse preset as new one" size="small" @click="setPresetValues('lidar', LidarDefPresetAlias)"></Button>
              </span>
            </div>

            <div class="field col-12 md:col-1" v-if="!update">
              <span class="p-float-label">
                <span class="mlwn-col-input">
                  <Checkbox data-cy="sensorsDefinitions__trajectoryCheckbox" v-model="trajectoryCheck" :binary="true" name="trajectory" class="mt-2" />
                  <label for="trajectory" class="ml-4 checkbox-label">Trajectory</label>
                </span>
              </span>
            </div>

            <div class="field col-12 md:col-3" v-if="!update">
              <span class="p-float-label">
                <span class="mlwn-col-input" v-if="trajectoryCheck">
                  <Dropdown data-cy="sensorsDefinitions__trajectoryDropdown" scroll-height="400px"
                            id="presetTypeAliasTrajectory" class="full-width" v-model="TrajectoryDefPresetAlias"
                            :options="trajectoryPresets" optionLabel="key" optionValue="key"  placeholder="Select preset"
                            @change="onPresetTrajectoryValueChange"
                  />
                  <label for="presetTypeAliasTrajectory">Select trajectory preset</label>
                </span>
              </span>
              <span v-if="TrajectoryDefPresetAlias && TrajectoryDefPresetAlias !== PRESETS_EMPTY_VALUE">
                <Button label="Reuse preset as new one" size="small" @click="setPresetValues('trajectory', TrajectoryDefPresetAlias)"></Button>
              </span>
            </div>

            <div class="col-12 md:col-12">
              <span>

                <Fieldset data-cy="sensorsDefinitions__cameraPresetTab" :legend="`Camera preset${CameraDefPresetAlias && !update ? ': ' + CameraDefPresetAlias : ''}`" v-if="cameraCheck && CameraDefPresetAlias"
                          :toggleable="true" :collapsed="collapsed.camera" @toggle="toggle('camera', $event)" class="row-toggle-fieldest">
                  <CameraDefPresetForm
                      ref="cameraDefPresetForm" data-cy="sensorsDefinitions__cameraPresetSection"
                      v-if="!CameraDefPresetAlias || showCameraPreset"
                      v-model="CameraDef"
                      :showTitle=false
                      :save-preset="(name: string) => onSave(name, PRESET_TYPES.CAMERA_DEF)"
                      :save-preset-enable="true"
                  >
                  </CameraDefPresetForm>
                </Fieldset>

                <Fieldset data-cy="sensorsDefinitions__lidarPresetTab" :legend="`LiDAR preset${LidarDefPresetAlias && !update ? ': ' + LidarDefPresetAlias : ''}`"  v-if="lidarCheck && LidarDefPresetAlias"
                          :toggleable="true" :collapsed="collapsed.lidar" @toggle="toggle('lidar', $event)" class="row-toggle-fieldest">
                  <LidarDefPresetForm
                      ref="lidarDefPresetForm" data-cy="sensorsDefinitions__lidarPresetSection"
                      v-if="!LidarDefPresetAlias || showLidarPreset"
                      v-model="LidarDef"
                      :showTitle=false
                      :save-preset="(name: string) => onSave(name, PRESET_TYPES.LIDAR_DEF)"
                      :save-preset-enable="true"
                  >
                  </LidarDefPresetForm>
                </Fieldset>

                <Fieldset data-cy="sensorsDefinitions__trajectoryPresetTab" :legend="`Trajectory preset${TrajectoryDefPresetAlias && !update ? ': ' + TrajectoryDefPresetAlias : ''}`" v-if="trajectoryCheck && TrajectoryDefPresetAlias"
                          :toggleable="true" :collapsed="collapsed.trajectory" @toggle="toggle('trajectory', $event)" class="row-toggle-fieldest">
                  <TrajectoryDefPresetForm
                      ref="trajectoryDefPresetForm" data-cy="sensorsDefinitions__trajectoryPresetSection"
                      v-if="!TrajectoryDefPresetAlias || showTrajectoryPreset"
                      v-model="TrajectoryDef"
                      :showTitle=false
                      :save-preset="(name: string) => onSave(name, PRESET_TYPES.TRAJECTORY_DEF)"
                      :save-preset-enable="true"
                  >
                  </TrajectoryDefPresetForm>
                </Fieldset>

                <div class="flex flex-row flex-wrap justify-content-between mt-6">
                  <Button data-cy="sensorsDefinitions__backButton" label="Back" icon="fa-solid fa-arrow-left"
                          class="p-button-info mr-2 mb-2 mlwn-button-submit" @click="stepBack"></Button>
                  <Button label="Cancel" icon="fa-solid fa-xmark" @click="onCancel" class="p-button-secondary mr-2 mb-2 mlwn-button-submit" v-if="false" />
                  <Button data-cy="sensorsDefinitions__nextButton" label="Next" type="submit" icon="fa-solid fa-arrow-right"
                          class="p-button-success mr-2 mb-2 mlwn-button-submit"></Button>
                </div>
              </span>
            </div>
          </div>
        </Form>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.row-toggle-fieldest {
  padding: 0;
  margin: 1rem 0 1rem 0;
  width: 100% !important;
  min-width: 100% !important;
}
.p-float-label label {
  left: 0.5rem;
}

.checkbox-label {
  margin-top: -3px;
}

</style>