import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, withCtx as _withCtx, createSlots as _createSlots, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "mlwn-multiple-input-list-simple" }
const _hoisted_2 = { class: "slot-block" }
const _hoisted_3 = { class: "flex justify-content-start flex-wrap" }
const _hoisted_4 = { key: 1 }

import { ref, Ref, watch, computed } from "vue"
import { v4 as uuidv4 } from 'uuid'


export const componentName = 'MultipleInputListSimple'

const __default__ = {
  name: componentName,
}

type ItemDataType<Type> = {
  value?: Type | undefined,
  __key?: string | undefined;
}


export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: {
  modelValue: {
    type: Array,
  },
  maxItems: {
    type: Number,
    default: -1,
    required: false
  },
  rowClass: {
    type: String,
    required: false,
    default: ''
  },
  title: {
    type: String,
    required: false,
    default: ''
  },
  itemLabel: {
    type: String,
    required: false,
    default: 'item'
  },
  defaultRowValue: {
    type: Function,
    required: false,
    default: () => { return { } }
  },
  minRowsCount: {
    type: Number,
    required: false,
    default: 0
  },
  toggleableRow: {
    type: Boolean,
    required: false,
    default: true
  },
  toggleableRowTitleCb: {
    type: Function,
    required: false,
    default: (value: any) => { return '' }
  },
  disableRowRemove: {
    type: Boolean,
    required: false,
    default: false
  }
},
  setup(__props, { emit: __emit }) {

const props = __props

const changeEmitName = 'update:modelValue'
const emits = __emit

const model = computed({
  get: () => props?.modelValue,
  set: (value: any) => {
    emits(changeEmitName, value)
  },
})

const addRow = () => {
  if (props.maxItems === -1 || items.value.length < props.maxItems) {
    items.value.push({__key: uuidv4(), value: props.defaultRowValue() as any}) 
  }
}

const items: Ref<ItemDataType<any>[]> = ref([])

if (props.modelValue) {
  for (const item of props.modelValue) {
    items.value.push({ __key: uuidv4(), value: item })
  }
}

if (props.minRowsCount > 0 && items.value.length < props.minRowsCount) {
  for (let i = 0; i < props.minRowsCount;i++) {
    addRow()
  }
}

const removeRow = (key: string) => {
  const index = items.value.findIndex(item => item.__key === key)
  if (index > -1) {
    items.value.splice(index, 1)
  }
}

watch(items, (newValue, oldValue) => {
  model.value = newValue.map(item => item.value)
}, { deep: true })
    
const layout = ref('list')

return (_ctx: any,_cache: any) => {
  const _component_Button = _resolveComponent("Button")!
  const _component_DataView = _resolveComponent("DataView")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_DataView, {
      value: items.value,
      layout: layout.value
    }, _createSlots({
      list: _withCtx((slotProps) => [
        _createElementVNode("div", {
          class: _normalizeClass(["flex justify-content-start flex-wrap row-data", __props.rowClass])
        }, [
          (!__props.disableRowRemove)
            ? _withDirectives((_openBlock(), _createBlock(_component_Button, {
                key: 0,
                class: "p-button-sm button",
                icon: "fa-solid fa-trash",
                severity: "danger",
                "aria-label": "Remove",
                onClick: ($event: any) => (removeRow(slotProps.data.__key))
              }, null, 8, ["onClick"])), [
                [
                  _directive_tooltip,
                  'Remove item',
                  void 0,
                  { bottom: true }
                ]
              ])
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_2, [
            _renderSlot(_ctx.$slots, "form", {
              value: slotProps.data.value,
              key: slotProps.data.__key
            })
          ])
        ], 2)
      ]),
      _: 2
    }, [
      (__props.title.length)
        ? {
            name: "header",
            fn: _withCtx(() => [
              _createTextVNode(_toDisplayString(__props.title), 1)
            ]),
            key: "0"
          }
        : undefined,
      (!__props.disableRowRemove)
        ? {
            name: "footer",
            fn: _withCtx(() => [
              _createElementVNode("div", _hoisted_3, [
                ((__props.maxItems === -1 || items.value.length < __props.maxItems))
                  ? _withDirectives((_openBlock(), _createBlock(_component_Button, {
                      key: 0,
                      icon: "fa-solid fa-plus",
                      label: 'Add ' + props.itemLabel,
                      class: "p-button-sm w-auto",
                      onClick: addRow
                    }, null, 8, ["label"])), [
                      [
                        _directive_tooltip,
                        'Add ' + props.itemLabel,
                        void 0,
                        { left: true }
                      ]
                    ])
                  : (_openBlock(), _createElementBlock("span", _hoisted_4, "Can not add more lines. Maximum is " + _toDisplayString(__props.maxItems), 1))
              ])
            ]),
            key: "1"
          }
        : undefined
    ]), 1032, ["value", "layout"])
  ]))
}
}

})