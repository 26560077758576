<script setup lang="ts">
/**
 * Load validation references, router, vue
 */
import { Form } from 'vee-validate';
import * as yup from 'yup';
import {ComputedRef, Ref, nextTick, ref, watch} from 'vue';
import {computed} from 'vue';

import InputTextWithValidation from '@/components/UI/Form/components/InputTextWithValidation.vue';
import ListWithValidation from '@/components/UI/Form/components/ListWithValidation.vue';
import DropdownWithValidation from '@/components/UI/Form/components/DropdownWithValidation.vue';
import DataBrowseSelectWithValidation from '@/components/UI/Form/components/DataBrowseSelectWithValidation.vue';
import {LidarDefModelType, LidarRule} from '@/helpers/types/PresetTypes';
import {CameraDefModelType, CameraRule} from '@/helpers/types/PresetTypes';
import {TrajectoryDefModelType, TrajectoryRule} from '@/helpers/types/PresetTypes';
import {useRouter} from 'vue-router';
import {Preset} from '@/gql/graphql';
import {PRESET_TYPES} from '@/helpers/constants';
import {usePresets} from '@/composables/usePresets';
import {useToast} from 'primevue/usetoast';

// Define the event emitter
const emit = defineEmits(['next', 'prevPage', 'onCancel']);

const onCancel = () => {
  emit('onCancel', router.currentRoute.value.name);
};

const stepBack = () => {
  emit('prevPage', router.currentRoute.value.name);
};

const props = defineProps({
  formData: {
    type: Object,
    required: true,
  },
});

const getLidarRules = () => {
  const lr =
    props.formData?.populationConfig?.LidarDef?.map((item: LidarDefModelType) => {
      return {name: item.name, rule: ''};
    }) ?? [];
  if (Array.isArray(props.formData?.populationConfig?.LidarRules)) {
    for (const rule of props.formData.populationConfig.LidarRules) {
      const i = lr.findIndex((item: LidarRule) => item.name === rule.name);
      if (i >= 0) lr[i].rule = rule.rule;
    }
  }
  return lr;
};

const getTrajectoryRules = () => {
  const tr =
    props.formData?.populationConfig?.TrajectoryDef?.map(
      (item: TrajectoryDefModelType) => {
        return {name: item.name, rule: ''};
      },
    ) ?? [];
  if (Array.isArray(props.formData?.populationConfig?.TrajectoryRules)) {
    for (const rule of props.formData.populationConfig.TrajectoryRules) {
      const i = tr.findIndex((item: TrajectoryRule) => item.name === rule.name);
      if (i >= 0) tr[i].rule = rule.rule;
    }
  }
  return tr;
};

const getCameraRules = () => {
  const cr =
    props.formData?.populationConfig?.CameraDef?.map((item: CameraDefModelType) => {
      return {name: item.name, rule: ''};
    }) ?? [];
  if (Array.isArray(props.formData?.populationConfig?.CameraRules)) {
    for (const rule of props.formData.populationConfig.CameraRules) {
      const i = cr.findIndex((item: CameraRule) => item.name === rule.name);
      if (i >= 0) cr[i].rule = rule.rule;
    }
  }
  return cr;
};

const initialValues = ref({
  srs: props.formData?.populationConfig?.srs ?? {
    path: '',
    volumeMountAlias: '',
  },
  eopsAngleUnit: props.formData?.populationConfig?.eopsAngleUnit ?? 'deg',
  //@ts-ignore
  eopsDir: props.formData?.populationConfig?.eopsDir?.map((item) => {
    return {_browser: item};
  }) ?? [
    {
      path: '',
      volumeMountAlias: '',
    },
  ],
  //@ts-ignore
  lidarDir: props.formData?.populationConfig?.lidarDir?.map((item) => {
    return {_browser: item};
  }) ?? [
    {
      path: '',
      volumeMountAlias: '',
    },
  ],
  //@ts-ignore
  trajectoryDir: props.formData?.populationConfig?.trajectoryDir?.map((item) => {
    return {_browser: item};
  }) ?? [
    {
      path: '',
      volumeMountAlias: '',
    },
  ],
  //@ts-ignore
  imageDir: props.formData?.populationConfig?.imageDir?.map((item) => {
    return {_browser: item};
  }) ?? [
    {
      path: '',
      volumeMountAlias: '',
    },
  ],
  dem: props.formData?.populationConfig?.dem ?? {
    path: '',
    volumeMountAlias: '',
  },
  demResolution: props.formData?.populationConfig?.demResolution ?? 10,

  camera: props.formData?.populationConfig?.Camera ? true : false,
  lidar: props.formData?.populationConfig?.Lidar ? true : false,
  trajectory: props.formData?.populationConfig?.Trajectory ? true : false,

  LidarRules: getLidarRules(),
  TrajectoryRules: getTrajectoryRules(),
  CameraRules: getCameraRules(),
});

const eopsAngleUnitOptions = [
  {
    value: 'deg',
    label: 'Degrees',
  },
  {
    value: 'rad',
    label: 'Radians',
  },
  {
    value: 'gon',
    label: 'Gons (gradians)',
  },
];

/**
 * Validation setup
 */
const schema = yup.object({
  srs: yup
    .object()
    .shape({
      _browser: yup
        .object()
        .shape({
          volumeMountAlias: yup.string().required().label('Volume Mount Alias'),
          path: yup.string().required().label('Value'),
        })
        .strict(),
    })
    .label('srs'),
  eopsAngleUnit: yup.string().required().label('Eops angle unit'),
  eopsDir: yup
    .array()
    .of(
      yup.object().shape({
        _browser: yup
          .object()
          .shape({
            volumeMountAlias: yup.string().required().label('Volume Mount Alias'),
            path: yup.string().required().label('Value'),
          })
          .strict(),
      }),
    )
    .strict(),
  lidarDir: yup
    .array()
    .of(
      yup.object().shape({
        _browser: yup
          .object()
          .shape({
            volumeMountAlias: yup.string().required().label('Volume Mount Alias'),
            path: yup.string().required().label('Value'),
          })
          .strict(),
      }),
    )
    .strict(),
  trajectoryDir: yup
    .array()
    .of(
      yup.object().shape({
        _browser: yup
          .object()
          .shape({
            volumeMountAlias: yup.string().required().label('Volume Mount Alias'),
            path: yup.string().required().label('Value'),
          })
          .strict(),
      }),
    )
    .strict(),
  imageDir: yup
    .array()
    .of(
      yup.object().shape({
        _browser: yup
          .object()
          .shape({
            volumeMountAlias: yup.string().required().label('Volume Mount Alias'),
            path: yup.string().required().label('Value'),
          })
          .strict(),
      }),
    )
    .strict(),
  dem: yup
    .object()
    .shape({
      _browser: yup
        .object()
        .shape({
          volumeMountAlias: yup.string().required().label('Volume Mount Alias'),
          path: yup.string().required().label('Value'),
        })
        .strict(),
    })
    .label('dem'),
  demResolution: yup.number().required().min(1).max(20).label('demResolution'),
  LidarRules: yup
    .array()
    .of(
      yup.object().shape({
        name: yup.string().required().label('Name'),
        rule: yup.string().required().label('Rule'),
      }),
    )
    .strict(),
  TrajectoryRules: yup
    .array()
    .of(
      yup.object().shape({
        name: yup.string().required().label('Name'),
        rule: yup.string().required().label('Rule'),
      }),
    )
    .strict(),
  CameraRules: yup
    .array()
    .of(
      yup.object().shape({
        name: yup.string().required().label('Name'),
        rule: yup.string().required().label('Rule'),
      }),
    )
    .strict(),
});

const router = useRouter();
// Function
const onSubmit = (values: any) => {
  let _values = JSON.parse(JSON.stringify(values));

  //@ts-ignore
  _values.imageDir = values.imageDir.map((item) => item?._browser);
  //@ts-ignore
  _values.lidarDir = values.lidarDir.map((item) => item?._browser);
  //@ts-ignore
  _values.eopsDir = values.eopsDir.map((item) => item?._browser);
  //@ts-ignore
  _values.trajectoryDir = values.trajectoryDir.map((item) => item?._browser);

  _values.LidarRulesPresetAlias = LidarRulesPresetAlias.value;
  _values.CameraRulesPresetAlias = CameraRulesPresetAlias.value;
  _values.TrajectoryRulesPresetAlias = TrajectoryRulesPresetAlias.value;

  emit('next', _values, router.currentRoute.value.name);
};

const {getPresets} = usePresets();
const {presets: demPresets} = getPresets({
  filter: {
    fields: [
      {
        type: 'equals',
        name: 'presetTypeAlias',
        value: PRESET_TYPES.DEM_DEF,
      },
    ],
  },
});

const DemDefPresetAlias: Ref<Preset | null | undefined> = ref(
  props.formData?.populationConfig?.DemDefPresetAlias ?? null,
);
const DemDef: Ref<any> = ref({
  DemDef: props.formData?.populationConfig?.DemDef ?? null,
});

const showDemPreset = ref(
  props.formData?.populationConfig?.DemDefPresetAlias ? true : false,
);

const demRef = ref(null);

const onPresetDemValueChange = (value: any) => {
  if (value && value.value) {
    showLidarPreset.value = false;

    const presetValue = Object.assign(
      {},
      demPresets.value.find((item) => item.key === value.value),
    );
    DemDef.value = presetValue.value;
    if (DemDef.value.DemDef) {
      let dem: any = {};
      for (const item of DemDef.value.DemDef) {
        dem[item.key] = item.value;
      }
      demRef?.value?.fillData(dem);
    }

    nextTick(() => {
      showLidarPreset.value = true;
    });
  }
};

const {presets: lidarPresets} = getPresets({
  filter: {
    fields: [
      {
        type: 'equals',
        name: 'presetTypeAlias',
        value: PRESET_TYPES.LIDAR_RULES,
      },
    ],
  },
});

watch(lidarPresets, () => {
  if (lidarPresets?.value) {
    const removeList: any = [];
    lidarPresets?.value.forEach((item) => {
      const presetRules = item.value.LidarRules;
      const definitonRules = initialValues.value.LidarRules;
      if (presetRules.length != definitonRules.length) {
        if (!removeList.includes(item)) {
          removeList.push(item);
        }
      } else {
        presetRules.forEach((rule: any) => {
          if (!definitonRules.find((f: any) => f.name === rule.key)) {
            if (!removeList.includes(item)) removeList.push(item);
          }
        });
        definitonRules.forEach((rule: any) => {
          if (!presetRules.find((f: any) => f.key === rule.name)) {
            if (!removeList.includes(item)) removeList.push(item);
          }
        });
      }
    });
    if (removeList && removeList.length > 0) {
      for (let i = 0; i < removeList.length; i++) {
        const index = lidarPresets?.value?.findIndex((x) => x.key === removeList[i].key);
        if (index || index === 0) {
          lidarPresets?.value?.splice(index, 1);
        }
      }
    }
  }
});

const LidarRulesPresetAlias: Ref<Preset | null | undefined> = ref(
  props.formData?.populationConfig?.LidarRulesPresetAlias ?? null,
);
const LidarRules: Ref<any> = ref({
  LidarRules: props.formData?.populationConfig?.LidarRules ?? null,
});

const showLidarPreset = ref(
  props.formData?.populationConfig?.LidarRulesPresetAlias ? true : false,
);

const lidarRef = ref(null);

const onPresetLidarValueChange = (value: any) => {
  if (value && value.value) {
    showLidarPreset.value = false;

    const presetValue = Object.assign(
      {},
      lidarPresets.value.find((item) => item.key === value.value),
    );
    LidarRules.value = presetValue.value;
    if (LidarRules.value.LidarRules) {
      lidarRef?.value?.fillData(LidarRules.value.LidarRules);
    }

    nextTick(() => {
      showLidarPreset.value = true;
    });
  }
};

const {presets: cameraPresets} = getPresets({
  filter: {
    fields: [
      {
        type: 'equals',
        name: 'presetTypeAlias',
        value: PRESET_TYPES.CAMERA_RULES,
      },
    ],
  },
});

watch(cameraPresets, () => {
  if (cameraPresets?.value) {
    const removeList: any = [];
    cameraPresets?.value.forEach((item) => {
      const presetRules = item.value.CameraRules;
      const definitonRules = initialValues.value.CameraRules;
      if (presetRules.length != definitonRules.length) {
        if (!removeList.includes(item)) {
          removeList.push(item);
        }
      } else {
        presetRules.forEach((rule: any) => {
          if (!definitonRules.find((f: any) => f.name === rule.key)) {
            if (!removeList.includes(item)) {
              removeList.push(item);
            }
          }
        });
        definitonRules.forEach((rule: any) => {
          if (!presetRules.find((f: any) => f.key === rule.name)) {
            if (!removeList.includes(item)) {
              removeList.push(item);
            }
          }
        });
      }
    });
    if (removeList && removeList.length > 0) {
      for (let i = 0; i < removeList.length; i++) {
        const index = cameraPresets?.value?.findIndex((x) => x.key === removeList[i].key);
        if (index || index === 0) {
          cameraPresets?.value?.splice(index, 1);
        }
      }
    }
  }
});

const CameraRulesPresetAlias: Ref<Preset | null | undefined> = ref(
  props.formData?.populationConfig?.CameraRulesPresetAlias ?? null,
);
const CameraRules: Ref<any> = ref({
  CameraRules: props.formData?.populationConfig?.CameraRules ?? null,
});

const showCameraPreset = ref(
  props.formData?.populationConfig?.CameraRulesPresetAlias ? true : false,
);

const cameraRef = ref(null);

const onPresetCameraValueChange = (value: any) => {
  if (value && value.value) {
    showCameraPreset.value = false;

    const presetValue = Object.assign(
      {},
      cameraPresets.value.find((item) => item.key === value.value),
    );
    CameraRules.value = presetValue.value;

    if (CameraRules.value.CameraRules) {
      cameraRef?.value?.fillData(CameraRules.value.CameraRules);
    }

    nextTick(() => {
      showCameraPreset.value = true;
    });
  }
};

const {presets: trajectoryPresets} = getPresets({
  filter: {
    fields: [
      {
        type: 'equals',
        name: 'presetTypeAlias',
        value: PRESET_TYPES.TRAJECTORY_RULES,
      },
    ],
  },
});

watch(trajectoryPresets, () => {
  if (trajectoryPresets?.value) {
    const removeList: any = [];
    trajectoryPresets?.value.forEach((item) => {
      const presetRules = item.value.TrajectoryRules;
      const definitonRules = initialValues.value.TrajectoryRules;
      if (presetRules.length != definitonRules.length) {
        if (!removeList.includes(item)) {
          removeList.push(item);
        }
      } else {
        presetRules.forEach((rule: any) => {
          if (!definitonRules.find((f: any) => f.name === rule.key)) {
            if (!removeList.includes(item)) removeList.push(item);
          }
        });
        definitonRules.forEach((rule: any) => {
          if (!presetRules.find((f: any) => f.key === rule.name)) {
            if (!removeList.includes(item)) removeList.push(item);
          }
        });
      }
    });
    if (removeList && removeList.length > 0) {
      for (let i = 0; i < removeList.length; i++) {
        const index = trajectoryPresets?.value?.findIndex(
          (x) => x.key === removeList[i].key,
        );
        if (index || index === 0) {
          trajectoryPresets?.value?.splice(index, 1);
        }
      }
    }
  }
});

const TrajectoryRulesPresetAlias: Ref<Preset | null | undefined> = ref(
  props.formData?.populationConfig?.TrajectoryRulesPresetAlias ?? null,
);
const TrajectoryRules: Ref<any> = ref({
  TrajectoryRules: props.formData?.populationConfig?.TrajectoryRules ?? null,
});

const showTrajectoryPreset = ref(
  props.formData?.populationConfig?.TrajectoryRulesPresetAlias ? true : false,
);

const trajectoryRef = ref(null);

const onPresetTrajectoryValueChange = (value: any) => {
  if (value && value.value) {
    showTrajectoryPreset.value = false;

    const presetValue = Object.assign(
      {},
      trajectoryPresets.value.find((item) => item.key === value.value),
    );
    TrajectoryRules.value = presetValue.value;
    if (TrajectoryRules.value.TrajectoryRules) {
      trajectoryRef?.value?.fillData(TrajectoryRules.value.TrajectoryRules);
    }

    nextTick(() => {
      showTrajectoryPreset.value = true;
    });
  }
};

const {createPreset} = usePresets();
const toast = useToast();

const savePreset = async (name: string, data: object, type: PRESET_TYPES) => {
  try {
    let _value = JSON.parse(JSON.stringify(data));

    let res = await createPreset({
      key: name,
      presetTypeAlias: type,
      value: {
        [type]: _value,
      },
      presetType: {
        __typename: undefined,
        alias: '',
        attributes: undefined,
        name: undefined,
      },
    });

    if (res.success) {
      toast.add({
        severity: 'success',
        summary: 'Success',
        detail: 'Succesfully created',
        life: 3000,
      });
    } else {
      toast.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Error occured while creating',
        life: 3000,
      });
    }
  } catch (err) {
    toast.add({
      severity: 'error',
      summary: 'Error',
      detail: 'Error occured while creating. ' + err.message,
      life: 3000,
    });
    console.error(err);
  }
};

const onSave = (name: string, type: PRESET_TYPES) => {
  switch (type) {
  case PRESET_TYPES.LIDAR_RULES:
    savePreset(name, LidarRules.value.LidarRules, type);
    break;
  case PRESET_TYPES.TRAJECTORY_RULES:
    savePreset(name, TrajectoryRules.value.TrajectoryRules, type);
    break;
  case PRESET_TYPES.CAMERA_RULES:
    savePreset(name, CameraRules.value.CameraRules, type);
    break;
  }
};

const selectedVolume = ref('');

const setBrowserVolume = (volumeMountAlias: string, browserRef: any) => {
  browserRef?.setBrowserVolume(volumeMountAlias);
};

const setVolume = (volumeMountAlias: string) => {
  selectedVolume.value = volumeMountAlias;
  for (const [key, el] of Object.entries(dataBrowseSelectRefs.value)) {
    console.log(key, el)
    setBrowserVolume(selectedVolume.value, el);
  }
};

const dataBrowseSelectRefs = ref<Record<string, InstanceType<typeof DataBrowseSelectWithValidation>>>({});
const setDataBrowseSelectRef = (el: InstanceType<typeof DataBrowseSelectWithValidation>) => {
  if (el) {
    // console.log("setDataBrowseSelectRef", el)
    dataBrowseSelectRefs.value[el.name] = el;
    // setBrowserVolume(selectedVolume.value, el);
  }
};

const cameraInputVisible = () => {};

const update = ref(router.currentRoute.value.path.indexOf('/edit/') > -1);
</script>

<template>
  <div class="grid">
    <div class="col-12">
      <div class="p-fluid">
        <Form
          :initial-values="initialValues"
          :validation-schema="schema"
          @submit="onSubmit"
          class="grid p-fluid"
        >
          <div class="grid col-12 formgrid">
            <Fieldset
              :legend="`SRS`"
              :toggleable="true"
              :collapsed="false"
              class="field col-12 md:col-12"
            >
              <div class="grid col-12 formgrid">
                <div class="field col-12 md:col-3">
                  <p>SRS PRJ file</p>
                  <DataBrowseSelectWithValidation
                    :leaf="'file'"
                    name="srs"
                    label="SRS PRJ file"
                    data-cy="inputConfigurations__srsPrjFile"
                    :set-parent-volume="setVolume"
                    :ref="setDataBrowseSelectRef"
                    :minimize="true"
                  ></DataBrowseSelectWithValidation>
                </div>
              </div>
            </Fieldset>

            <Fieldset
              :legend="`DEM`"
              :toggleable="true"
              :collapsed="false"
              class="field col-12 md:col-12"
            >
              <div class="grid col-12 formgrid">
                <div class="field col-12 md:col-3">
                  <p class="label">Select preset</p>
                  <Dropdown
                    data-cy="inputConfigurations__demSelectPreset"
                    scroll-height="400px"
                    id="presetTypeAliasDem"
                    class="full-width"
                    v-model="DemDefPresetAlias"
                    :options="demPresets"
                    optionLabel="key"
                    optionValue="key"
                    placeholder="Select preset"
                    @change="onPresetDemValueChange"
                  />
                </div>

                <div class="field col-12 md:col-3">
                  <p>DEM configuration</p>
                  <DataBrowseSelectWithValidation
                    ref="demRef"
                    :leaf="'file'"
                    name="dem"
                    label="DEM configuration"
                    :minimize="true"
                  ></DataBrowseSelectWithValidation>
                </div>

                <div class="field col-12 md:col-3">
                  <p>Resolution [m]</p>
                  <InputTextWithValidation
                    name="demResolution"
                    label="DEM resolution [m]"
                    class="input"
                    :minimize="true"
                  ></InputTextWithValidation>
                </div>
              </div>
            </Fieldset>

            <Fieldset
              :legend="`EOPs`"
              :toggleable="true"
              :collapsed="false"
              class="field col-12 md:col-12"
            >
              <div class="grid col-12 formgrid">
                <div class="field col-12 md:col-3">
                  <p>Directory (multiple)</p>
                  <ListWithValidation name="eopsDir" :minimize="true" label="">
                    <template #form="{fieldName}">
                      <DataBrowseSelectWithValidation
                        data-cy="inputConfigurations__eopsDir"
                        :leaf="'dir'"
                        :ref="setDataBrowseSelectRef"
                        :set-parent-volume="setVolume"
                        :name="`${fieldName}._browser`"
                        :selected-volume="selectedVolume"
                        :minimize="true"
                        label="EOPs directory"
                      >
                        data-cy="inputConfigurations__eopsDirectory"
                      </DataBrowseSelectWithValidation>
                    </template>
                  </ListWithValidation>
                </div>

                <div class="field col-12 md:col-3">
                  <p>Unit of Omega, Phi and Kappa angles</p>
                  <DropdownWithValidation
                    name="eopsAngleUnit"
                    label="Unit of Omega, Phi and Kappa angles"
                    data-cy="inputConfigurations__angles"
                    :options="eopsAngleUnitOptions"
                    optionValue="value"
                    optionLabel="label"
                    class="input"
                    :minimize="true"
                  >
                  </DropdownWithValidation>
                </div>

                <div class="field col-12 md:col-6"></div>
              </div>
            </Fieldset>

            <Fieldset
              :legend="`Camera/Image`"
              v-if="
                initialValues.camera &&
                initialValues.CameraRules &&
                initialValues.CameraRules.length > 0
              "
              :toggleable="true"
              :collapsed="false"
              class="field col-12 md:col-12"
            >
              <div class="grid col-12 formgrid">
                <div class="field col-12 md:col-3">
                  <ListWithValidation
                    name="imageDir"
                    label="Directory (multiple)"
                    :minimize="true"
                  >
                    <template #form="{fieldName}">
                      <DataBrowseSelectWithValidation
                        data-cy="inputConfigurations__cameraDir"
                        :leaf="'dir'"
                        :name="`${fieldName}._browser`"
                        label="Image dir"
                        :set-parent-volume="setVolume"
                        :minimize="true"
                        :ref="setDataBrowseSelectRef"
                      ></DataBrowseSelectWithValidation>
                    </template>
                  </ListWithValidation>
                </div>

                <div class="field col-12 md:col-3">
                  <p class="label">Select preset</p>
                  <Dropdown
                    data-cy="inputConfigurations__cameraPresetDropdown"
                    scroll-height="400px"
                    id="presetTypeAliasCamera"
                    class="full-width"
                    v-model="CameraRulesPresetAlias"
                    :options="cameraPresets"
                    optionLabel="key"
                    optionValue="key"
                    placeholder="Select preset"
                    @change="onPresetCameraValueChange"
                  />
                </div>

                <div class="field col-12 md:col-6">
                  <ListWithValidation
                    :disableRowRemove="true"
                    name="CameraRules"
                    label="Rules"
                    :save-preset-enable="update"
                    :save-preset="(name: string) => onSave(name, PRESET_TYPES.CAMERA_RULES)"
                    :max-items="initialValues.CameraRules.length"
                    ref="cameraRef"
                  >
                    <template #form="{fieldName}">
                      <div class="formgroup-inline">
                        <InputTextWithValidation
                          :name="`${fieldName}.name`"
                          label="Camera name"
                          :disabled="true"
                          :minimize="true"
                        ></InputTextWithValidation>
                        <InputTextWithValidation
                          class="flex-1 mr-0"
                          data-cy="inputConfigurations__cameraRule"
                          :size="40"
                          :name="`${fieldName}.rule`"
                          label="Camera rule"
                          :minimize="true"
                        ></InputTextWithValidation>
                      </div>
                    </template>
                  </ListWithValidation>
                </div>
              </div>
            </Fieldset>

            <Fieldset
              :legend="`LiDAR`"
              v-if="
                initialValues.lidar &&
                initialValues.LidarRules &&
                initialValues.LidarRules.length > 0
              "
              :toggleable="true"
              :collapsed="false"
              class="field col-12 md:col-12"
            >
              <div class="grid col-12 formgrid">
                <div class="field col-12 md:col-3">
                  <ListWithValidation
                    name="lidarDir"
                    :minimize="true"
                    label="Directory (multiple)"
                  >
                    <template #form="{fieldName}">
                      <DataBrowseSelectWithValidation
                        data-cy="inputConfigurations__lidarDir"
                        :leaf="'dir'"
                        :name="`${fieldName}._browser`"
                        label="Lidar directory"
                        :set-parent-volume="setVolume"
                        :minimize="true"
                        :ref="setDataBrowseSelectRef"
                      ></DataBrowseSelectWithValidation>
                    </template>
                  </ListWithValidation>
                </div>

                <div class="field col-12 md:col-3">
                  <p class="label">Select preset</p>
                  <Dropdown
                    data-cy="inputConfigurations__lidarPresetDropdown"
                    scroll-height="400px"
                    id="presetTypeAliasLidar"
                    class="full-width"
                    v-model="LidarRulesPresetAlias"
                    :options="lidarPresets"
                    optionLabel="key"
                    optionValue="key"
                    placeholder="Select preset"
                    @change="onPresetLidarValueChange"
                  />
                </div>

                <div class="field col-12 md:col-6">
                  <ListWithValidation
                    name="LidarRules"
                    :disableRowRemove="true"
                    label="Rules"
                    :max-items="initialValues.LidarRules.length"
                    ref="lidarRef"
                    :minimize="true"
                    :save-preset-enable="update"
                    :save-preset="(name: string) => onSave(name, PRESET_TYPES.LIDAR_RULES)"
                  >
                    <template #form="{fieldName}">
                      <div class="formgroup-inline">
                        <InputTextWithValidation
                          :name="`${fieldName}.name`"
                          data-cy="inputConfigurations__lidarRule"
                          label="Lidar name"
                          :minimize="true"
                          :disabled="true"
                        ></InputTextWithValidation>
                        <InputTextWithValidation
                          class="flex-1 mr-0"
                          :size="40"
                          :minimize="true"
                          :name="`${fieldName}.rule`"
                          label="Lidar rule"
                        ></InputTextWithValidation>
                      </div>
                    </template>
                  </ListWithValidation>
                </div>
              </div>
            </Fieldset>

            <Fieldset
              :legend="`Trajectory`"
              v-if="
                initialValues.trajectory &&
                initialValues.TrajectoryRules &&
                initialValues.TrajectoryRules.length > 0
              "
              :toggleable="true"
              :collapsed="false"
              class="field col-12 md:col-12"
            >
              <div class="grid col-12 formgrid">
                <div class="field col-12 md:col-3">
                  <ListWithValidation
                    name="trajectoryDir"
                    :minimize="true"
                    label="Directory (multiple)"
                  >
                    <template #form="{fieldName}">
                      <DataBrowseSelectWithValidation
                        data-cy="inputConfigurations__trajectoryDir"
                        :leaf="'dir'"
                        :name="`${fieldName}._browser`"
                        label="Trajectory dir"
                        :set-parent-volume="setVolume"
                        :minimize="true"
                        :ref="setDataBrowseSelectRef"
                      ></DataBrowseSelectWithValidation>
                    </template>
                  </ListWithValidation>
                </div>

                <div class="field col-12 md:col-3">
                  <p class="label">Select preset</p>
                  <Dropdown
                    data-cy="inputConfigurations__trajectoryPresetDropdown"
                    scroll-height="400px"
                    id="presetTypeAliasTrajectory"
                    class="full-width"
                    v-model="TrajectoryRulesPresetAlias"
                    :options="trajectoryPresets"
                    optionLabel="key"
                    optionValue="key"
                    placeholder="Select preset"
                    @change="onPresetTrajectoryValueChange"
                  />
                </div>

                <div class="field col-12 md:col-6">
                  <ListWithValidation
                    :disableRowRemove="true"
                    name="TrajectoryRules"
                    label="Trajectory rules"
                    :max-items="initialValues.TrajectoryRules.length"
                    :minimize="true"
                    ref="trajectoryRef"
                    :save-preset-enable="update"
                    :save-preset="(name: string) => onSave(name, PRESET_TYPES.TRAJECTORY_RULES)"
                  >
                    <template #form="{fieldName}">
                      <div class="formgroup-inline">
                        <InputTextWithValidation
                          :name="`${fieldName}.name`"
                          label="Trajectory name"
                          :disabled="true"
                          :minimize="true"
                        ></InputTextWithValidation>
                        <InputTextWithValidation
                          class="flex-1 mr-0"
                          data-cy="inputConfigurations__trajectoryRule"
                          :size="40"
                          :name="`${fieldName}.rule`"
                          label="Trajectory rule"
                          :minimize="true"
                        ></InputTextWithValidation>
                      </div>
                    </template>
                  </ListWithValidation>
                </div>
              </div>
            </Fieldset>

            <div class="field col-12 md:col-12 mt-6">
              <div class="flex flex-row flex-wrap justify-content-between">
                <Button
                  label="Back"
                  icon="fa-solid fa-arrow-left"
                  class="p-button-info mr-2 mb-2 mlwn-button-submit"
                  @click="stepBack"
                ></Button>
                <Button
                  data-cy="inputConfigurations__nextButton"
                  label="Cancel"
                  icon="fa-solid fa-xmark"
                  @click="onCancel"
                  class="p-button-secondary mr-2 mb-2 mlwn-button-submit"
                  v-if="false"
                />
                <Button
                  data-cy="inputConfigurations__nextButton"
                  label="Next"
                  type="submit"
                  icon="fa-solid fa-arrow-right"
                  class="p-button-success mr-2 mb-2 mlwn-button-submit"
                ></Button>
              </div>
            </div>
          </div>
        </Form>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.label {
  margin-bottom: 1rem;
}
.input {
  margin-top: 0.5rem;
}
</style>
