
<template>
  <div class="mlwn-multiple-input-list">
    <DataView :value="items" :layout="layout" >
      <template #header v-if="title.length && showTitle">
        {{ title }}
      </template>
      <template #list="slotProps">
        <div class="flex flex-row flex-wrap row-data" :class="rowClass">
          <Fieldset :legend="toggleableRowTitleCb(slotProps.data.value)" :toggleable="toggleableRow" :collapsed="false" class="mlwn-row-toggle-fieldest">
            <template #legend>
                <div class="flex align-items-center text-primary">
                    <span>{{ toggleableRowTitleCb(slotProps.data.value) }}</span>
                    <Button v-if="!disableRowRemove" class="remove-button" icon="fa-solid fa-trash" severity="danger" :aria-label="'Remove'" v-tooltip.bottom="'Remove '+props.itemLabel"  @click="removeRow(slotProps.data.__key)" />
                </div>
            </template>
            <div class="slot-block">
              <slot :value="slotProps.data.value" :key="slotProps.data.__key" name="form"></slot>
            </div>
          </Fieldset>
        </div>
      </template>
      <template #footer v-if="!disableRowRemove">
        <div class="flex">
          <div class="col-6">
            <Button v-if="maxItems === -1 || items.length < maxItems" icon="fa-solid fa-plus" :label="'Add ' + props.itemLabel" v-tooltip.left="'Add ' + props.itemLabel" class="p-button-sm w-auto" @click="addRow" />
            <span v-else>Can not add more lines. Maximum is {{ maxItems }}</span>
          </div>
          <div class="col-6">
            <div class="flex justify-content-end" v-if="savePresetEnable">
              <div>
                <Button severity="secondary" v-if="items.length > 0 && !saveLabelVisible" icon="fa-solid fa-save" label="Save preset" v-tooltip.left="'Save preset'" class="p-button-sm button-margin" @click="onSavePreset" />
                <Button severity="secondary" v-if="saveLabelVisible && items.length > 0" icon="fa-solid fa-eye-slash" v-tooltip.left="'Hide'" class="p-button-sm" @click="onSavePreset" />
              </div>
              <div>
                <InputText v-model="presetName" v-if="saveLabelVisible" placeholder="New preset name"/>
              </div>
              <div>
                <Button v-if="saveLabelVisible && items.length > 0" icon="fa-solid fa-save" v-tooltip.left="'Save preset'" class="p-button-sm" @click="onSave(presetName)" />
              </div>
            </div>
          </div>          
        </div>
      </template>
    </DataView>
  </div>
</template>

<script lang="ts" setup>
import { ref, Ref, watch, computed } from "vue"
import { v4 as uuidv4 } from 'uuid'

const props = defineProps({
  modelValue: {
    type: Array,
  },
  maxItems: {
    type: Number,
    default: -1,
    required: false
  },
  rowClass: {
    type: String,
    required: false,
    default: ''
  },
  title: {
    type: String,
    required: false,
    default: ''
  },
  showTitle: {
    type: Boolean,
    required: false,
    default: true
  },
  itemLabel: {
    type: String,
    required: false,
    default: 'item'
  },
  defaultRowValue: {
    type: Function,
    required: false,
    default: () => { return { } }
  },
  savePreset: {
    type: Function,
    required: false,
    default: () => { return { } }
  },
  minRowsCount: {
    type: Number,
    required: false,
    default: 0
  },
  savePresetEnable: {
    type: Boolean,
    required: false,
    default: false
  },
  toggleableRow: {
    type: Boolean,
    required: false,
    default: true
  },
  toggleableRowTitleCb: {
    type: Function,
    required: false,
    default: (value: any) => { return '' }
  },
  disableRowRemove: {
    type: Boolean,
    required: false,
    default: false
  }
})

type ItemDataType<Type> = {
  value?: Type | undefined,
  __key?: string | undefined;
}

const presetName = ref('')
const saveLabelVisible = ref(false)

const changeEmitName = 'update:modelValue'
const emits = defineEmits<{(e: typeof changeEmitName, id: string): void }>()

const model = computed({
  get: () => props?.modelValue,
  set: (value: any) => {
    emits(changeEmitName, value)
  },
})

const addRow = () => {
  if (props.maxItems === -1 || items.value.length < props.maxItems) {
    items.value.push({__key: uuidv4(), value: props.defaultRowValue() as any}) 
  }
}

const onSavePreset = () => {
  saveLabelVisible.value = !saveLabelVisible.value
  if (saveLabelVisible.value) {
    presetName.value = ''
  }
}

const onSave = (name: string) => {
  if (name.length > 0) {    
    props.savePreset(name)
    onSavePreset()
  }
}

const items: Ref<ItemDataType<any>[]> = ref([])

if (props.modelValue) {
  for (const item of props.modelValue) {
    items.value.push({ __key: uuidv4(), value: item })
  }
}

if (props.minRowsCount > 0 && items.value.length < props.minRowsCount) {
  for (let i = 0; i < props.minRowsCount;i++) {
    addRow()
  }
}

const removeRow = (key: string) => {
  const index = items.value.findIndex(item => item.__key === key)
  if (index > -1) {
    items.value.splice(index, 1)
  }
}

watch(items, (newValue, oldValue) => {
  model.value = newValue.map(item => item.value)
}, { deep: true })
    
const layout = ref('list')
</script>

<script lang="ts">
export const componentName = 'MultipleInputList'

export default {
  name: componentName,
}
</script>

<style lang="scss" scoped>
.mlwn-multiple-input-list {
  padding: 0rem 0;
}

.mlwn-multiple-input-list .row-data {
  align-content: center;
  width: 100%;
  justify-content: space-between;
  border-bottom: 0 !important;
}

.mlwn-multiple-input-list .row-data div {
  align-self: center;
}

.mlwn-multiple-input-list .row-data > .slot-block > .grid {
  margin: 0;
}

.mlwn-multiple-input-list .mlwn-row-toggle-fieldest {
  padding: 0;
  margin: 1rem 0 0 0;
  width: 100% !important;
  min-width: 100% !important;
}

.mlwn-multiple-input-list .p-dataview-footer {
  margin-top: 1rem;
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.mlwn-multiple-input-list .p-dataview-header {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.mlwn-multiple-input-list .p-fieldset .p-fieldset-content {
  padding: 0 !important;
}
.remove-icon-block {
    /* background-color: green; */
    display: flex;
    justify-content: end;
    position: absolute;
    right: 0;
    top: -1rem;
}

.p-fieldset-content {
    position: relative;
}

.mlwn-multiple-input-list .button-margin {
  margin-right: 24px;
}

.mlwn-multiple-input-list .save-preset-panel {
  padding-left: 0.6rem;
}

.mlwn-multiple-input-list .save-preset {
  display: flex; 
  flex-direction: row; 
  width: 60%;
}
</style>