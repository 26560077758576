<template>
  <span class="p-inputnumber p-component p-inputwrapper p-inputwrapper-filled">
    <input 
      :id="props.id" 
      :class="['p-inputtext','p-component',{ 
        'p-filled': filled,
        'p-invalid': inputError
      }]" 
      type="text" 
      v-model="localModel" 
      :disabled="props.disabled"
      />
  </span>
</template>

<script setup lang="ts">
import { computed, ref, watch } from 'vue';

const emit = defineEmits(['update:modelValue'])

const props = defineProps({
  maxFractionDigits: {
    type: Number,
    default: 2,
    required: false
  },
  modelValue: {
    type: Number,
    default: 0
  },
  disabled: {
    type: Boolean,
    default: false
  },
  id: {
    type: String,
    default: 'inputId'
  }
})

const filled = computed(() => {
  return localModel.value.toString() !== ''
})

const localModel = ref<string>(props.modelValue.toString());
const inputError = ref(false)

watch(localModel, (value) => {
  console.log("Fdjskfjdsk")
  const standardizedInput = value.replace(',', '.');
  const numberPattern = /^-?\d+(\.\d+)?$/;
  if (numberPattern.test(standardizedInput)) {
    const valueNumber = parseFloat(standardizedInput)
    inputError.value = false
    console.log("ayes")
    emit('update:modelValue', valueNumber)
  } else {
    console.log("no")
    inputError.value = true
  }
})
</script>